/* @font-face {
  font-family: 'black';
  src: url('../../../fonts/proximanova-black-webfont.eot');
  src: url('../../../fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/proximanova-black-webfont.woff2') format('woff2'), url('../../../fonts/proximanova-black-webfont.woff') format('woff'), url('../../../fonts/proximanova-black-webfont.ttf') format('truetype'), url('../../../fonts/proximanova-black-webfont.svg#proxima_novablack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bold';
  src: url('../../../fonts/proximanova-extrabold-webfont.eot');
  src: url('../../../fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/proximanova-extrabold-webfont.woff2') format('woff2'), url('../../../fonts/proximanova-extrabold-webfont.woff') format('woff'), url('../../../fonts/proximanova-extrabold-webfont.ttf') format('truetype'), url('../../../fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'regular';
  src: url('../../../fonts/proximanova-regular-webfont.eot');
  src: url('../../../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../../../fonts/proximanova-regular-webfont.woff2') format('woff2'), url('../../../fonts/proximanova-regular-webfont.woff') format('woff'), url('../../../fonts/proximanova-regular-webfont.ttf') format('truetype'), url('../../../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
} */

#bottommenu {
    width: 100%;
    height: 108px;
    /* background: #866ec7; */
    /* background: #005B31; */
    /* background: rgba(166, 29, 58, 0.5); */
    /* box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.25); */
    color: #fff;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    position: fixed;
    z-index: 1000;
    bottom: 0px;
    font-family: "Poppins", sans-serif !important;
    font-weight: bold;
    background: linear-gradient(to top, #000000b3, transparent);
    /* background-image: url("../../images/background-gradient.png"); */
}

.mobmenu {
    display: none;
}

.widthmenuicon {
    display: none;
}

.login-image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.centered {
    margin: auto !important;
    display: flex !important;
    flex-direction: column !important;
    width: 50% !important;
    height: 100%;
}

.colored-text {
    color: #202329;
    font-weight: 700;
    text-align: left;
    width: 100%;
    font-size: 28px;
}

.spanned-btn {
    outline: none !important;
    border: none !important;
    background: transparent;
    color: blue;
    font-size: small;
    text-align: left;
    line-height: 1.8;
    padding: 0;
}

.linked-btn {
    border: none;
    outline: 0;
    padding: 0;
    font-size: 14px;
    background-color: #fff;
    color: #b3e5fc;
    text-align: left;
    width: 100%;
}

.linked-btn:hover {
    color: #03a9f4;
    cursor: pointer;
}

.skip-btn {
    border-bottom: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
}

.skip-btn:hover {
    color: #fff;
    border-bottom: none;
}

.register-btn {
    color: #03a9f4;
    text-align: center;
    background: transparent;
    outline: none !important;
    border: none !important;
    font-size: 16px;
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .bottomaniamtion {
        transform: translateY(500px);
    }
    .mobmenu {
        display: block;
        position: fixed;
        bottom: 10px;
        left: 20px;
        /* background: white; */
        border-radius: 50%;
        /* padding: 10px; */
        /* box-shadow: 0 0 20px; */
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        z-index: 12;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 30%);
        border-radius: 50%;
    }
    .widthmenuicon {
        width: 40px;
        display: block;
    }
    .mobmenuclose {
        display: none;
        position: fixed;
        bottom: 120px;
        left: 20px;
        /* background: white; */
        border-radius: 50%;
        /* padding: 10px; */
        /* box-shadow: 0 0 20px; */
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        z-index: 12;
        box-shadow: 0 5px 10px 2px rgb(0 0 0 / 30%);
        border-radius: 50%;
    }
}

.flexicon {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 5px;
    margin-top: 5px;
}

@media screen and (max-width: 600px) {
    .login-image {
        display: none;
    }
    .centered {
        width: 70% !important;
    }
}

@media (max-width: 900px) {
    .flexicon {
        justify-content: start;
        overflow: auto;
    }
}

.flexiconspeaker {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 20px;
    margin-top: 30px;
}

.widthicon {
    width: 40px;
    margin: auto;
    height: auto;
    z-index: 1;
}

.circleimg {
    width: 30px;
    position: relative;
    left: 45px;
    transform: scale(1.2) rotate(0deg);
    transform-origin: right;
    transition: all 1s ease-in-out;
}


/* @media only screen and (orientation: portrait) {
    .circleimg {
        left: 33px !important;
    }
    .hideMobile {
        display: none !important;
    }
    .flexcolumn:hover .circleimg {
        left: 30px !important;
    }
    .activeflexcolumn .circleimg {
        left: 33px !important;
    }
} */

.flexcolumn:hover .circleimg {
    /* animation: rotateCirle 1s ease-in-out both; */
    transform: scale(1.2) rotate(180deg);
}

.activeflexcolumn .circleimg {
    transform: scale(1.2) rotate(270deg);
    animation: rotateCirle 0.5s linear;
}

.activeflexcolumn:hover .circleimg {
    transform: scale(1.2) rotate(270deg);
    /* animation: rotateCirle 1s ease-in-out both; */
}

@keyframes rotateCirle {
    0% {
        transform: scale(1.2) rotate(180deg);
    }
    100% {
        transform: scale(1.2) rotate(270deg);
    }
}

.widthicon:active {
    transform: scale(0.8);
}

.flexrow {
    list-style: none;
    margin: 0;
    position: absolute;
    padding: 0;
    bottom: -100px;
    right: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    opacity: 0;
}

.flexcolumn {
    display: flex;
    flex-direction: column;
    width: 120px;
    margin: 3px 5px;
    cursor: pointer;
    /* min-width: 100px; */
}

.flexcolumnspeaker {
    display: flex;
    flex-direction: column;
    /* width: 100px; */
    margin: 5px 10px;
    cursor: login-image pointer;
}

.cenetrtext {
    text-align: center;
    font-size: 12px;
    margin-left: 15px;
    /* font-weight: bold; */
}

.bottomtext {
    text-transform: capitalize;
    font-family: "Poppins", sans-serif !important;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.startcamerabtn {
    background-color: white;
    color: #000;
    border-radius: 5px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    outline: none !important;
    border: 0;
    width: 250px;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
    /* border-right: 2px solid #144E82; */
}


/* .startcamerabtn:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  transform: scale(0.9);

} */

.borderrightbtn {
    border-right: 1px solid #c5c6c6;
    height: 25px;
    margin-left: 2px;
    margin-right: 2px;
}

.LABEL {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    font-size: 20px;
    color: black;
}

.red-text {
    color: red !important;
}

.green-text {
    color: #144e82;
}

.logoutbtnposition {
    position: fixed;
    right: 20px;
    cursor: pointer;
}

.mutebtnposition {
    position: fixed;
    left: 20px;
    display: flex;
    flex-direction: row;
}

.marginrightmutebtn {
    margin-right: 1rem;
}

.breakoutbutton {
    display: flex;
    justify-content: center;
    margin: 30px;
}

.agendaButton {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.nursebreakout {
    padding-left: 2%;
}

.btnBlueGreen {
    z-index: 1001;
    color: white;
    width: 150px;
    background-color: #144e82;
    outline: none !important;
    border: 0;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
    font-weight: bold;
    height: 40px;
}

.btnBlueGreen:hover {
    background-color: transparent;
    border: 2px solid #144e82;
    color: #144e82;
}

.margin {
    margin-left: 10px;
}

.active {
    background-color: transparent;
    border: 1px solid #144e82;
    color: #144e82;
    outline: none !important;
}

.agendaImage {
    padding: 0px 30px 24px 30px !important;
    font-size: 13px;
    color: #666;
}

.modal {
    overflow: auto !important;
}

.toggleBottom {
    bottom: 12vh !important;
    opacity: 1 !important;
}

@media (max-width: 700px) {
    .flexrow {
        right: 10px;
        bottom: -250px !important;
        transition: all 0.6s ease-in;
    }
    .widthicon {
        width: 35px;
    }
    .circleimg {
        left: 22px;
        width: 27px;
    }
    .cenetrtext {
        font-size: 10px;
    }
    .flexrow .flexcolumn {
        width: 70px;
    }
    .toggleBottom {
        bottom: 100px !important;
    }
}

@media screen and (min-width: 1800px) {
    .flexrow {
        right: 23.7vw;
    }
}


/* @media only screen and (orientation: portrait) { */


/* 
@media screen and (min-width: 500px) {
    .circleimg {
        left: 33px !important;
    }
    .hideMobile {
        display: none !important;
    }
    .flexcolumn:hover .circleimg {
        left: 30px !important;
    }
    .activeflexcolumn .circleimg {
        left: 33px !important;
    }
} */