.events-bg {
    background-image: url("../images/feature_bg.png");
    background-position: center;
    background-size: cover;
    height: 100%;
}

.events-bg .container-fluid {
    padding: 0 60px;
}

.events-conference {
    background-color: #F8F6F4 !important;
    padding: 70px 0px;
}

.events-conference .section-title-area {
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
}

.events-conference .section-title-area .sub-title {
    color: #4bc74b;
    display: inline-block;
    font-family: "Poppins", Sans-serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.section-heading {
    color: #333333;
    font-weight: 600;
    margin: 0;
    font-size: 56px;
    line-height: 62px;
    letter-spacing: -1.68px;
    padding-bottom: 24px;
    font-family: "Poppins", sans-serif;
}

.events-conference .section-title-area p {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    line-height: 35px;
}

.single-item {
    background-color: white;
    box-shadow: 0px 10px 35px 0px rgb(0 0 0 / 8%);
}

.single-item:hover {
    background: #FFAC27;
    box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 69%);
    transition: 1s;
}

.single-item .content {
    padding: 30px 10px;
    height: 300px;
    text-align: left;
}

.single-item .content .eve {
    margin-bottom: 10px;
    height: 160px;
}

.single-item .content p {
    margin-bottom: 5px;
}

.single-item .content .eventsubtitle {
    color: #ed9121;
    text-transform: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: normal;
}

.single-item:hover .eventsubtitle {
    color: #ffffff;
}

.single-item .content .btn {
    background: none;
    color: #333333;
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 1;
}

.btn-link {
    text-decoration: none !important;
}

.videoEnterButton {
    border-radius: 7px;
    background: rgb(21, 29, 143);
    background: linear-gradient(90deg, rgba(21, 29, 143, 1) 32%, rgba(4, 95, 230, 1) 100%);
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
    transition: all 0.5s;
    cursor: pointer;
    animation: pulse 1.5s linear infinite;
}

.skipButton {
    border-radius: 7px;
    background: rgb(21, 29, 143);
    background: linear-gradient(90deg, rgba(21, 29, 143, 1) 32%, rgba(4, 95, 230, 1) 100%);
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
    transition: all 0.5s;
    cursor: pointer;
    animation: pulse 1.5s linear infinite;
    position: fixed;
    bottom: 3vh;
    right: 5vw;
    z-index: 1000000;
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
}

.backButton {
    border-radius: 7px;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    padding: 10px 20px;
    transition: all 0.5s;
    cursor: pointer;
    position: fixed;
    bottom: 10vh;
    right: 0vw;
    z-index: 1000000;
}

.backButton>img {
    width: 4vw;
}

.videoEnterButton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.videoEnterButton span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.videoEnterButton:hover span {
    padding-right: 25px;
}

.videoEnterButton:hover span:after {
    opacity: 1;
    right: 0;
}


/* .single-item .content .btn::after {
  content: "";
  height: 5px;
  width: 98px;
  background: #0073E4;
  position: absolute;
  left: 0;
  bottom: -5px;
  z-index: -1;
  transition: all 0.4s ease;
} */

.single-item .content .btn:hover::after {
    width: 15px;
}

.btn-link i {
    margin-left: 6px;
    position: relative;
    top: 5px;
    font-size: 20px;
}

.sm {
    font-size: 27px;
    color: #333333;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin: 0;
    transition: all ease 0.5s;
}

.enter-event {
    outline: none !important;
    background: transparent;
    border: 3px solid #ed9121;
    border-radius: 5px;
    /* margin-left: 50px; */
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.download-certificate {
    outline: none !important;
    background: transparent;
    border: 3px solid #ed9121;
    border-radius: 5px;
    color: #707070;
    transition: all 0.6s linear;
    margin: 0px !important;
}

.download-certificate:hover {
    background: #ed9121;
    color: #ffffff;
    border: 3px solid #ed9121;
}

.enter-event:hover {
    background: #ffffff;
    /* color: #ffffff; */
    border: 3px solid #ffffff;
}

.hero-area {
    padding: 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 100% !important;
    height: 838px;
    /* margin-bottom: 80px; */
    /* background-image: url("../images/banner/ban1.png"); */
}


/* .banner-row {
  padding-bottom: 150px;
} */

.hero-content-wrapper {
    padding-top: 100px;
    padding-bottom: 80px;
}

.hero-content-wrapper .hero-content {
    padding-left: 130px;
}

.hero-content-wrapper .title-section-area {
    position: relative;
    max-width: 650px;
}

.hero-content-wrapper .title-section-area .lg {
    position: relative;
    margin-bottom: 20px;
}

.hero-content-wrapper .title-section-area .sub-title {
    position: absolute;
    left: -107px;
    top: 37px;
    transform: rotate(-90deg);
    color: #fff;
    font-size: 42px;
    letter-spacing: -2.52px;
    padding-bottom: 0;
    font-weight: 400;
}

.hero-content-wrapper .title-section-area .sub-title::before {
    content: "";
    height: 2px;
    width: 57px;
    background: #fff;
    position: absolute;
    top: 38px;
    left: -73px;
}

.hero-content-wrapper .title-section-area p {
    color: #ffffff;
    margin-bottom: 60px;
    font-size: 28px;
    line-height: normal;
    font-family: 'Roboto';
}

.hero-content-wrapper .button-and-social-area {
    overflow: hidden;
    padding-bottom: 70px;
    position: relative;
    display: flex;
}

.hero-content-wrapper .button-and-social-area .register-btn {
    background: white;
    border: 2px solid #0073e2;
    border-radius: 0;
    border-radius: 15px;
    color: #0073e2;
}

.hero-content-wrapper .button-and-social-area .register-btn:hover {
    background: #0073e2;
    border: 2px solid #fff;
    color: white;
}

.hero-content-wrapper .button-and-social-area .downloadbtn {
    background: rgb(21, 29, 143);
    background: linear-gradient(90deg, rgba(21, 29, 143, 1) 32%, rgba(4, 95, 230, 1) 100%);
    box-shadow: 1px 1px 5px gray;
    color: white;
    border-radius: 15px;
}

.hero-content-wrapper .button-and-social-area .downloadbtn:hover {
    border: 1px solid white;
    transition: 0s;
}

.hero-content-wrapper .button-and-social-area .social-icons {
    position: relative;
    padding-left: 108px;
    margin-left: 30px;
    margin-top: 10px;
}

.hero-content-wrapper .button-and-social-area .social-icons li {
    display: inline-block;
    margin-right: 40px;
}

.hero-content-wrapper .button-and-social-area .social-icons li a {
    color: white;
    font-size: 24px;
}

.hero-content-wrapper .button-and-social-area .social-icons li a:hover {
    color: #2794d7;
}

.hero-content-wrapper .button-and-social-area .social-icons::before {
    content: "";
    height: 2px;
    width: 80px;
    background: #ffff;
    position: absolute;
    top: 10px;
    left: 0;
}

.hero-content-wrapper .banner-info-meta {
    padding-bottom: 20px;
}

.hero-content-wrapper .banner-info-meta li {
    display: inline-block;
    margin-right: 80px;
    margin-top: 20px;
}

.hero-content-wrapper .banner-info-meta li p {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.countdown {
    margin-bottom: 60px;
}

.countdown .counter-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    text-align: center;
    color: #343a40;
    margin-right: 50px;
    padding: 35px 0;
    position: relative;
}

.countdown .counter-item i {
    font-size: 105px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #2B75B7 !important;
}

.countdown .counter-item:last-of-type {
    margin-right: 0;
}

.countdown .counter-item span {
    font-size: 28px;
    display: block;
    line-height: 18px;
    letter-spacing: .96px;
    font-weight: 700;
    color: #343a40;
}

.countdown .counter-item .smalltext {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    color: #343a40;
}

.countdown .counter-item:nth-of-type(1) i {
    color: white;
}

.countdown .counter-item:nth-of-type(2) i {
    color: white;
    top: 2px;
    -webkit-transform: rotate(-148deg);
    -ms-transform: rotate(-148deg);
    transform: rotate(-148deg);
}

.countdown .counter-item:nth-of-type(3) i {
    color: white;
}

.countdown .counter-item:nth-of-type(4) i {
    color: white;
}

.banner-title {
    font-size: 71px;
    color: white;
    line-height: normal;
    letter-spacing: normal;
    font-weight: bold;
    font-family: 'Roboto';
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.position-marquee {
    width: 93%;
    position: absolute;
    top: 0;
    left: 0;
    color: red;
    font-size: 25px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 1;
}


/* New CSS By Bhavik */

.circle2 {
    height: 500px;
    width: 500px;
}

.cir {
    border-radius: 50%;
    border: 1px dashed rgb(255, 255, 255);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 169px 233.750px;
}

.sun {
    display: block;
    /* background: black; */
    border-radius: 50%;
    height: 250px;
    width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 294px 358.750px;
    background: radial-gradient(circle at 100px 100px, rgb(255, 255, 255), rgb(211, 208, 208));
    padding: 20px;
}

.size1 {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.earth25 {
    animation: earth2-rotate 9s linear infinite;
}

.earth24 {
    animation: earth2-rotate 10s linear infinite;
}

.earth23 {
    animation: earth2-rotate 11s linear infinite;
}

.earth22 {
    animation: earth2-rotate 12s linear infinite;
}

.earth2 {
    animation: earth2-rotate 13s linear infinite;
}

@keyframes earth2-rotate {
    0% {
        transform: rotate(0deg) translateX(250px) rotate(0deg);
    }
    100% {
        transform: rotate(360deg) translateX(250px) rotate(-360deg);
    }
}

section,
.section-padding {
    margin-bottom: 100px;
    position: relative;
}

.btnEvent {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    background: #ed9121;
    /* height: 50px; */
    padding: 2px 35px;
    line-height: 50px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

.downArrow {
    width: 40px;
    height: auto;
    cursor: pointer;
    padding: 5px;
    background: #f5f5fa;
    box-shadow: -5px -5px 10px rgb(255 255 255 / 50%), 5px 5px 10px rgb(170 170 204 / 25%), 10px 10px 20px rgb(170 170 204 / 50%), -10px -10px 20px #fff;
    border-radius: 50%;
    transition: all 2s linear;
    animation: transfromArrow 2s linear infinite;
}

@keyframes transfromArrow {
    0%,
    100%,
    20%,
    50%,
    80% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-15px);
    }
}