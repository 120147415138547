#portraitModeOnly {
    display: none;
}

@media only screen and (orientation: portrait) {
    #expoViewer {
        /* opacity: 0.3; */
        pointer-events: none;
    }
    body {
        overflow-y: hidden;
    }
    .overlayPotraitMode {
        height: 100vh;
    }
    #portraitModeOnly {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1200;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        text-align: center;
        font-size: xx-large;
        background: rgba(0, 0, 0, 0.8);
    }
    #portraitModeOnly img {
        width: 2em;
        margin-bottom: 0.8em;
    }
    #portraitModeOnly p {
        color: #FFFFFF;
        font-size: 0.8em;
    }
}

@media only screen and (orientation: landscape) {
    #portraitModeOnly {
        display: none;
    }
}

.iframe-position {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
}

.mainvideoEle {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    object-fit: fill;
    z-index: 11111;
    /* transition: all 2s linear; */
}

.imageClass {
    height: 100%;
    width: 100%;
    object-fit: fill;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-user-drag: none;
    /* background-image: url("/images/viewer/stallImages/Stall\ First\ image.jpg"); */
    min-height: 100vh;
}

#filter-card {
    position: relative;
    margin: 0.5rem 0 1rem 0;
    background-color: #fff;
    transition: box-shadow 0.25s;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    height: 190px;
    margin: 10px;
    border-radius: 10px;
}

#filter-card .card-image {
    height: 130px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#filter-card .card-image img {
    border-radius: 10px;
    display: block;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
}

#filter-card .card-content {
    padding: 5px;
    border-radius: 0 0 2px 2px;
}

#filter-card .card-content .card-title {
    line-height: 1.1;
    margin-bottom: 0;
}

.audiVideo:fullscreen {
    position: absolute;
    top: 330px;
    height: 260px;
    width: 430px;
    left: 750px;
    background: black;
}

.loader {
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 1px solid #282c34;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 2s linear infinite;
    margin: 4px 7px;
    border-top-width: 1px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.audiVideo {
    position: absolute;
    top: 2.35vh;
    height: 62vh;
    left: 24.4vw;
    width: 53.6vw;
    background: black;
    object-fit: fill;
}

.fullscreenBgImage {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
}

.positionmeet {
    width: 72%;
    height: 64%;
    position: fixed;
    top: 47.3%;
    left: 50.6%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.iframe-embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
}

.iframe-embed-wrapper {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.iframe-embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .positionmeet {
        height: 56%;
        top: 46%;
    }
    .tile-view #filmstripRemoteVideos {
        height: 100vh !important;
    }
}

.meetlayer {
    width: 73%;
    height: 64%;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    /* background: rgba(0,0,0,0.2); */
}

.posoitionlogo {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 120px;
    height: 100px;
    z-index: 350;
}

.invitebtn {
    background: var(--blue);
    border: none;
    padding: 8px;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    border-radius: 5px;
}

.positiveinvitebtn {
    display: flex;
    position: absolute;
    top: 10px;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, 10px);
}

.dialogewidth {
    width: 600px;
    height: auto;
}

@media (max-width: 600px) {
    .posoitionlogo {
        width: 45px;
        height: 45px;
    }
}


/* .widthlogo {
    width: 120px;
  } */

@media (max-width: 600px) {
    /* .widthlogo {
      width: 50px;
  
    } */
}


/* fullscreen button */

.fullscreenbutton {
    position: fixed !important;
    top: 21%;
    right: 7%;
    background-color: var(--blue) !important;
    color: #fff !important;
    width: 40px;
    height: 40px;
}


/* mobile device overlay */

.overlaylandscape {
    display: none;
}

@media (max-width: 600px) {
    .overlaylandscape {
        position: fixed;
        z-index: 1525;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.rotatetext {
    color: white;
    font-size: 1.3rem;
    text-align: center;
}

.rotateimg {
    width: 70px;
    margin-bottom: 1rem;
}


/* mobile device overlay end */

@media (max-width: 600px) {
    .audiVideo {
        top: 31.25vh;
        height: 23vh;
        background-color: transparent;
        object-fit: contain;
        left: 0;
        width: 100vw;
    }
}

.game {
    position: relative;
    text-align: center;
    font-weight: 600;
    color: #03a9f4;
    transition: transform 0.3s linear;
}

.game-span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    word-break: break-all;
}

.game:hover {
    transform: scale(0.9);
}

@media only screen and (orientation: portrait) {
    .circleimg {
        left: 33px !important;
    }
    .hideMobile {
        display: none !important;
    }
    .flexcolumn:hover .circleimg {
        left: 30px !important;
    }
    .activeflexcolumn .circleimg {
        left: 33px !important;
    }
}