.center-video-popup {
    position: fixed;
    justify-content: center;
    top: 50%;
    left: 50%;
    width: 700px;
    transform: translate(-50%, -50%);
    z-index: 1100;
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  
  .close-popup {
      position: fixed;
      right: 20px;
      font-size: 25px;
      float: right;
      cursor: pointer;
      font-weight: bold;
      margin-top: -0.5em;
      color: #000;
    }
  
    .overlay{
        position: fixed;
        z-index: 1050;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.95);
    }