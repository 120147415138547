.rectangle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 50px;
  height: 50px;
  background: #144E82;
  transform: scale(0);
  border-radius: 50%;
  color: white;
  opacity: 0;
  overflow: hidden;
  animation: scale-in 0.3s ease-out forwards, expand 0.35s 0.25s ease-out forwards;
}

.notification-text {
  display: flex;
  align-items: center;
  padding: 0 16px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  animation: fade-in 0.65s ease-in forwards;
}

@keyframes scale-in {
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes expand {
  50% {
    width: 350px;
    border-radius: 6px;
  }

  100% {
    width: 300px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 3px 3px -1px rgba(0, 0, 0, .12);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.notificationposition {
  position: fixed;
  z-index: 2000;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;

}
.marginbottomrectangle{
  margin-right: 1rem;
}
@media(max-width:600px){
  .notificationposition{
    flex-direction: column;
top: 20%;
  }
  .marginbottomrectangle{
    margin-bottom: 1rem;
    margin-right: 0;
  }
}
.notificationposition1 {
  position: fixed;
  z-index: 2000;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}