.loader {
    border: 2px solid #fff;
    border-radius: 50%;
    border-top: 1px solid #282c34;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 2s linear infinite;
    border-top-width: 1px;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }