.transparent-header-bg {
  position: fixed;
  /* width: 100%; */
  height: 70px;
  top: 10px;
  left: 10px;
  background-color: transparent;
  z-index: 5;
}

/* --------------------------------------------------------------------- */

/* Selection Page Header */

/* --------------------------------------------------------------------- */

.white-header-bg {
  position: fixed;
  width: 100%;
  /* height: 70px; */
  top: 10px;
  left: 10px;
  /* background-color: #fff; */
  /* box-shadow: 0px 3px 8px #09357d; */
  /* border: 2px solid #09357d; */
}

.selection-page-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  color: #144E82;
  text-shadow: 0px 1px 1px #144E82;
}

/* --------------------------------------------------------------------- */

/* Entrance Page Header */

/* --------------------------------------------------------------------- */

.logo-position {
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translate(-50%, -50%);
  width: 130px;
  z-index: 10;
  cursor: pointer;
}

.change-player-btn {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(-50%, -50%);
  width: 60px;
  z-index: 10;
  cursor: pointer;
}

/* --------------------------------------------------------------------- */

/* Lobby Page Header */

/* --------------------------------------------------------------------- */

/* --------------------------------------------------------------------- */

/* Webinar Page Header */

/* --------------------------------------------------------------------- */

.room-logo-position {
  /* position: absolute;
    top: 50%;
    left: 12%;
    transform: translate(-50%, -50%); */
  width: 130px;
  /* z-index: 10; */
  cursor: pointer;
}

.room-back-arrow-position {
  width: 40px;
  z-index: 10;
  cursor: pointer;
  margin-right: 10px;
  position: fixed;
  left: 60px;
  top: 20px;
  box-shadow: 0 5px 10px 2px rgba(118, 192, 67, 0.3);
  border-radius: 50%;
}

@media(max-width:600px) {
  .room-back-arrow-position {
    width: 30px;
  }
}

/* --------------------------------------------------------------------- */

/* Entertainment Page Header */

/* --------------------------------------------------------------------- */

/* --------------------------------------------------------------------- */

/* Meeting Page Header */

/* --------------------------------------------------------------------- */

.logopositionleft {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 200;
}

.widthangleslogo {
  width: 152px !important;
  cursor: pointer;
}

@media(max-width:600px) {
  .widthangleslogo {
    width: 120px !important;
    cursor: pointer;
  }
}

.widthangleslogo1 {
  width: 170px !important;
  ;
  cursor: pointer;
}

.logopositionright {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 200;
}

.widthboihringerlogo {
  width: 140px !important;
  cursor: pointer;
}

@media(max-width:600px) {
  .widthboihringerlogo {
    width: 100px !important;
    cursor: pointer;
  }
}