  /* @font-face {
    font-family: 'black';
    src: url('../../../fonts/proximanova-black-webfont.eot');
    src: url('../../../fonts/proximanova-black-webfont.eot?#iefix') format('embedded-opentype'),
      url('../../../fonts/proximanova-black-webfont.woff2') format('woff2'),
      url('../../../fonts/proximanova-black-webfont.woff') format('woff'),
      url('../../../fonts/proximanova-black-webfont.ttf') format('truetype'),
      url('../../../fonts/proximanova-black-webfont.svg#proxima_novablack') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins', sans-serif;
    src: url('../../../fonts/proximanova-extrabold-webfont.eot');
    src: url('../../../fonts/proximanova-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
      url('../../../fonts/proximanova-extrabold-webfont.woff2') format('woff2'),
      url('../../../fonts/proximanova-extrabold-webfont.woff') format('woff'),
      url('../../../fonts/proximanova-extrabold-webfont.ttf') format('truetype'),
      url('../../../fonts/proximanova-extrabold-webfont.svg#proxima_novaextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
   font-family: 'Poppins', sans-serif;
    src: url('../../../fonts/proximanova-regular-webfont.eot');
    src: url('../../../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
      url('../../../fonts/proximanova-regular-webfont.woff2') format('woff2'),
      url('../../../fonts/proximanova-regular-webfont.woff') format('woff'),
      url('../../../fonts/proximanova-regular-webfont.ttf') format('truetype'),
      url('../../../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
  } */

  body {
    font-family: 'Poppins', sans-serif !important;
    scroll-behavior: smooth;
  }


  @media(max-width:1100px) {

    html,
    body {
      min-width: auto !important;
      overflow-y: auto !important;
      overflow: -moz-scrollbars-vertical !important;

      /* height: 100vh; */
    }

    @-moz-document url-prefix() {

      html,
      body {
        min-width: auto !important;
        overflow-y: auto !important;
        overflow: auto !important;
        height: auto !important;
      }
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 07 - SIMPLE BLOCK STRUCTURE */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .blocks-container {
    position: relative;
    width: 100%;
  }

  .block {
    position: relative;
    width: 100%;
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 08 - BLOCK "TYPE 1" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-1 {
    height: 100vh;
    /* min-height: 600px; */
  }

  .block.type-1.type-1-1 {
    height: auto;
    min-height: 0px;
  }

  .block.type-1.type-1-1 .clip {
    height: 78%;
  }

  .numbers {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }

  .numbers .col-md-3 {
    text-align: center;
    cursor: pointer;
  }

  .numbers .col-md-3:before {
    position: absolute;
    left: 0px;
    bottom: -125px;
    background: #fff;
    width: 100%;
    height: 125px;
    content: "";
    z-index: 0;
  }

  .numbers .col-md-3:after {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 1px;
    background: rgba(255, 255, 255, 0.3);
    content: "";
  }

  .numbers .col-md-3:last-child:after {
    display: none;
  }

  .numbers .thumbs-image {
    position: absolute;
    left: 0px;
    bottom: -125px;
    width: 100%;
    opacity: 0;
    z-index: -1;
    backface-visibility: hidden;
  }

  .numbers .num {
    display: block;
    width: 100%;
    color: #144E82;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    padding: 25px 0px;
    position: relative;
  }

  .numbers .num-text {
    display: block;
    width: 100%;
    color: #dcdcdc;
    font-size: 14px;
    text-transform: uppercase;
    padding-bottom: 40px;
    position: relative;
  }

  .type-1-text {
    height: 100%;
    position: relative;
  }

  .boldfont {
    font-weight: 700;
  }

  .type-1-text p {
    color: #dddddd;

  }

  .type-1-text .row {
    height: 100%;
  }

  .type-1-text .type-1-center {
    text-align: center;
    height: 100%;
    display: table;
    vertical-align: middle;
  }

  .type-1-text .button {
    margin: 0px 15px;
    margin-bottom: 60px;
  }

  .type-1-text article {
    margin-top: 145px;
  }

  .type-1-text article.table {
    display: table-cell;
    vertical-align: middle;
    float: none;
    margin-top: 0px;
  }

  .marvel-device-wrap {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .marvel-device.iphone6plus {
    height: 853px;
    transform: scale(0.65);
    -moz-transform: scale(0.65);
    -o-transform: scale(0.65);
    -ms-transform: scale(0.65);
    -webkit-transform: scale(0.65);
    position: relative;
    float: left;
    display: block;
    margin-top: -125px;
  }

  .marvel-device-wrap .iphone-image-wrap {
    position: absolute;
    left: 50%;
    top: 95px;
    margin-left: -118px;
    height: 412px;
    overflow: hidden;
  }

  .marvel-device-wrap .iphone-image-wrap-move {
    position: relative;
    float: left;
    -webkit-animation: magic-move 7s infinite;
    animation: magic-move 7s infinite;
  }

  .marvel-device-wrap .iphone-image-wrap img {
    position: relative;
    float: left;
  }

  #content-wrapper .parallax-bg-text * {
    color: #fff;
  }

  #content-wrapper .parallax-bg-text p,
  #content-wrapper .parallax-bg-text ul li,
  #content-wrapper .parallax-bg-text .page-tagline .description {
    color: rgba(255, 255, 255, 0.8);
  }

  .text-start {
    text-align: left !important;
  }

  @media (min-width: 991px) {
    .numbers .col-md-3:hover:before {
      bottom: 0px;
    }

    .numbers .col-md-3:hover .num-text {
      color: #555;
    }

    .numbers .col-md-3:hover .thumbs-image {
      width: 100%;
      left: 0px;
      bottom: 125px;
      opacity: 1;
      z-index: 1;
    }
  }

  @media (max-width: 991px) {
    .block.type-1 {
      height: auto;
      min-height: 0px;
    }

    .type-1-text .button {
      margin-bottom: 10px;
    }

    .numbers {
      position: relative;
      background: #a39b90;
    }

    .type-1-text {
      padding-top: 70px;
    }

    .block.type-1.type-1-1 .type-1-text {
      padding-top: 0px;
    }

    .block.type-1.type-1-1 .type-1-text article {
      margin-top: 65px;
    }

    .numbers .num-text {
      padding-bottom: 20px;
    }

    .numbers .col-md-3 {
      padding-top: 40px;
      padding-bottom: 17px;
    }

    .numbers .col-md-3:before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .type-1-text p {
      padding-bottom: 20px;
    }

    .marvel-device-wrap {
      display: none;
    }

    .block.type-1.type-1-1 .clip {
      height: 100%;
    }

    .block.type-1.type-1-1 .type-1-text article {
      margin-top: 65px;
      margin-bottom: 20px;
    }

    .type-1-text .type-1-center {
      overflow: hidden;
    }
  }

  @keyframes magic-move {
    0% {
      margin-top: 0px;
    }

    14% {
      margin-top: 0px;
    }

    28% {
      margin-top: -412px;
    }

    42% {
      margin-top: -412px;
    }

    56% {
      margin-top: -824px;
    }

    70% {
      margin-top: -824px;
    }

    84% {
      margin-top: 0px;
    }

    100% {
      margin-top: 0px;
    }
  }

  @-webkit-keyframes magic-move {
    0% {
      margin-top: 0px;
    }

    14% {
      margin-top: 0px;
    }

    28% {
      margin-top: -412px;
    }

    42% {
      margin-top: -412px;
    }

    56% {
      margin-top: -824px;
    }

    70% {
      margin-top: -824px;
    }

    84% {
      margin-top: 0px;
    }

    100% {
      margin-top: 0px;
    }
  }

  /*background*/
  .bg {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-position: 50% 0px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    background-attachment: scroll;
    background-image: url('../images/loginbanner.jpg');
  }

  .bg img {
    position: absolute;
  }

  .bg video {
    position: absolute;
  }

  /* .bg-span {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.55);
  } */
  /* 
  .bg-span.light {
      background: rgba(0, 0, 0, 0.35);
  } */

  .clip {
    clip: rect(auto, auto, auto, auto);
    height: 100vh;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 0px;
  }

  .type-1-text {
    height: 100%;
    position: relative;
  }

  .type-1-text h1,
  .type-1-text h2 {
    color: #fff;
  }

  .type-1-text p {
    color: #dddddd;

  }

  .type-1-text .row {
    height: 100%;
  }

  .type-1-text .type-1-center {
    text-align: center;
    height: 100%;
    display: table;
    vertical-align: middle;
  }

  .type-1-text .button {
    margin: 0px 15px;
    margin-bottom: 60px;
  }

  .type-1-text article {
    margin-top: 145px;
  }

  .type-1-text article.table {
    display: table-cell;
    vertical-align: middle;
    float: none;
    margin-top: 0px;
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 04 - GLOBAL SETTINGS */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /*table*/
  .table-view {
    height: 100%;
    width: 100%;
    display: table;
  }

  .row-view {
    display: table-row;
  }

  .cell-view {
    display: table-cell;
    vertical-align: middle;
    height: inherit;
  }

  /*buttons*/
  .button {
    /* position: relative;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1); */
    background: #144E82;
    /* padding: 15px 25px;
    display: inline-block;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    height: 50px;
    width: 120px; */

    border-radius: 4px;
    height: 48px;
    width: 130px;
    font-size: 16px;
    font-weight: 500;
    -webkit-transition: all 200ms linear !important;
    transition: all 200ms linear !important;
    padding: 0 35px;
    letter-spacing: 0.2px;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
    border: none;
    color: white;
  }


  .loginbutton {
    color: #fff;
    font-family: "JosefinSans-600", sans-serif;
    display: inline-block;
    text-align: center;
    /* line-height: 47px; */
    background-color: #3FA9F4;
    border: 1.5px solid #3FA9F4;
    padding: .5rem 1rem;
    width: 100%;
    text-transform: capitalize;
    border-radius: 6px;
    /* background-size: 210% 100%;
    background-position: 99%; */
  }

  /* .loginbutton:hover {
    transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
    background-position: 0%;
    color: #144E82;
    
} */
  .btn-primary-line-dark {
    color: white !important;
    background-color: #144E82 !important;
    border: 1px solid #144E82;
  }

  .btn-primary-line-dark:active,
  .btn-primary-line-dark:focus,
  .btn-primary-line-dark:hover {
    color: #144E82 !important;
    background-color: transparent !important;
    box-shadow: 0 6px 15px 0 rgba(46, 59, 98, .4) !important;
    border: 1px solid #144E82 !important;
  }

  .button.size-2 {
    line-height: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button.size-3 {
    line-height: 20px;
    padding: 10px 12px;
    font-size: 10px;
  }

  .button.type-1 {
    background: #fff;
    color: #144E82;
    text-shadow: none;
    box-shadow: none;
  }

  /* .button:after {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 5px;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    content: "";
  } */

  .button.type-1:after {
    border: 2px #144E82 solid;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: none;
  }

  .button span {
    position: relative;
    float: left;
    z-index: 1;
  }

  .button:hover {
    color: #fff;
  }

  .button.type-1:hover {
    background: #144E82;
  }

  .button:hover:after {
    width: 100%;
    background: rgba(0, 0, 0, 0.02);
  }

  .button.black {
    background: #222222 !important;
    color: #144E82;
  }

  .button.black:hover {
    color: #fff;
  }

  .button.black:after {
    background: #000;
  }

  .play-wrap {
    position: relative;
    float: left;
    width: 76px;
    height: 76px;
  }

  .play {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -35px;
    margin-top: -35px;
    width: 70px;
    height: 70px;
    border: 3px solid #fff;
    border-radius: 100px;
    z-index: 1;
  }

  .play:after {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -5px;
    margin-top: -10px;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 15px solid #144E82;
    border-bottom: 10px solid transparent;
  }

  .play:hover {
    background: #144E82;
    border: 3px solid #144E82;
    transform: rotate(-90deg) scale(0.9);
    -o-transform: rotate(-90deg) scale(0.9);
    -moz-transform: rotate(-90deg) scale(0.9);
    -ms-transform: rotate(-90deg) scale(0.9);
    -webkit-transform: rotate(-90deg) scale(0.9);
  }

  .play:hover:after {
    border-left: 15px solid #fff;
  }

  /*nopadding class*/
  .nopadding {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  /*content wrapper*/
  #content-wrapper {
    padding-top: 0px;
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  /*typography*/
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0px !important;
  }

  .titel-left {
    position: relative;
  }

  .titel-left span {
    color: #144E82;
  }

  .titel-left:after {
    position: absolute;
    left: -25px;
    top: 0px;
    content: "";
    width: 5px;
    height: 100%;
    background: #144E82;
  }

  .titel-top {
    position: relative;
  }

  .titel-top:after {
    position: absolute;
    left: 50%;
    top: -25px;
    content: "";
    width: 30px;
    margin-left: -15px;
    height: 5px;
    background: #144E82;
  }

  /*slider*/
  .slider {
    position: relative;
    float: left;
    width: 100%;
  }

  .swiper-pagination-switch {
    width: 9px;
    height: 9px;
    background: #ebebeb;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .swiper-pagination-switch:before {
    display: none;
  }

  .swiper-active-switch {
    background: #144E82;
    height: 23px;
  }

  /*form styles*/
  form {
    position: relative;
    float: left;
    width: 100%;
  }

  .inputclass {
    background: #f8f8f8;
    border: 1px solid #ced4da;
    height: calc(2.25rem + 2px);
    /* line-height: 43px; */
    padding: 0.375rem 0.75rem;
    color: #777777;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 45%;
    border-radius: 5px;
    /* margin: 4px 2%; */
  }

  textarea {
    width: 94%;
    height: 125px;
  }

  ::-webkit-input-placeholder {
    color: #777777;
  }

  :-moz-placeholder {
    color: #777777;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: #777777;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #777777;
  }

  .submit-wraper {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 32px;
  }

  form input[type="submit"] {
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    width: 100%;
    height: 100%;
    border: 0px;
    z-index: 2;
  }

  .error-class {
    border-color: #ff0036 !important;
  }

  /*form popup*/
  .form-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 12;
    display: none;
  }

  .form-popup-close-layer {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .form-popup-content {
    max-width: 500px;
    width: 90%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    padding: 25px;
    left: 50%;
    top: 50%;
    background: rgba(255, 255, 255, 0.8);
    border: 10px #144E82 solid;
  }

  .form-popup .text {
    color: #777;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  }

  /*default swiper arrows*/
  .default-arrow {
    width: 52px;
    height: 52px;
    position: absolute;
    top: 50%;
    margin-top: -26px;
    background: #144E82;
    cursor: pointer;
  }

  .default-arrow:hover {
    background: #f2f2f2;
  }

  .default-arrow span {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
    line-height: 52px;
    display: block;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }

  .default-arrow:hover span {
    color: #144E82;
  }

  .default-arrow.left {
    -webkit-box-shadow: 3px 4px 10px rgba(0, 0, 0, .1), inset -3px 0 #daa508;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, .1), inset -3px 0 #daa508;
    left: 60px;
  }

  .default-arrow.right {
    -webkit-box-shadow: -4px 3px 10px rgba(0, 0, 0, .1), inset 3px 0 #daa508;
    box-shadow: -4px 3px 10px rgba(0, 0, 0, .1), inset 3px 0 #daa508;
    right: 60px;
  }

  /*map*/
  #map-canvas {
    height: 300px;
    background: #f2f2f2;
  }

  .block #map-canvas {
    margin-bottom: 60px;
  }

  @media (max-width: 991px) {
    .mob-hide {
      display: none;
    }

    .bg {
      position: fixed;
    }

    .default-arrow {
      display: none;
    }
  }

  @media (max-width: 767px) {

    .h1,
    .h2 {
      font-size: 40px;
      margin-bottom: 20px !important;
    }

    /* .button {
      width: 100%;
      margin-left: 0px !important;
      margin-top: 0px !important;
    } */

    .button span {
      width: 100%;
      text-align: center;
    }

    input[type="password"],
    input[type="text"],
    input[type="email"],
    select {
      width: 94%;
    }
  }

  /*   
.loaderBtn {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid rgba(0, 0, 0, 0.15);
  width: 16px;
  height: 16px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin-left: 1px;
}

.button:hover .loaderBtn {
  border: 2px solid #1C3664;
  border-top: 2px solid #005D62;
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} */
  /*video*/
  .block.type-1 * {
    -webkit-bakface-visibility: hidden;
  }


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 05 - HEADER */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  header {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 11;
    -webkit-backface-visibility: hidden;
  }

  #logo {
    position: relative;
    float: left;
    margin-left: 50px;
    margin-top: 22px;
  }

  #logo img {
    position: relative;
    float: left;
  }

  #logo img.act {
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
  }

  nav {
    position: relative;
    float: right;
    padding-right: 45px;
  }

  nav a {
    position: relative;
    float: left;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    height: 39px;
    line-height: 39px;
    padding: 0px 15px;
    margin: 30px 5px 0px 5px;
    font-family: 'Poppins', sans-serif !important;

  }

  nav a:hover {
    background: #144E82;
    color: #fff;
  }

  nav a.act {
    background: #144E82;
  }

  nav ul {
    position: relative;
    float: left;
    margin-bottom: 0;
  }

  nav ul li {
    background: none;
    position: relative;
    float: left;
  }

  nav ul li:before,
  nav ul li:after {
    display: none;
  }

  nav .submeny a:after {
    position: absolute;
    left: 50%;
    bottom: -2px;
    margin-left: -7px;
    width: 0px;
    height: 0px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #144E82;
    content: "";
  }

  nav .submeny .submeny-top:before {
    position: absolute;
    left: 0px;
    bottom: -11px;
    width: 100%;
    content: "";
    height: 11px;
  }

  nav .submeny:hover a:after,
  nav .submeny a.act:after {
    bottom: -7px;
  }

  nav ul ul {
    position: absolute;
    left: 0px;
    top: 84px;
    width: 100%;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15);
  }

  nav ul ul li {
    position: relative;
    float: left;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }

  nav ul ul li a {
    height: 0px;
    font-size: 0px;
    line-height: 0px;
    padding: 0px;
    margin: 0px;
    color: #777777;
    background: #fff;
    width: 200%;
    text-align: center;
    left: -50%;
  }

  nav ul ul li a:after {
    display: none;
  }

  nav .submeny:hover ul li a {
    height: 39px;
    font-size: 13px;
    line-height: 39px;
  }

  nav .submeny:hover .submeny-top {
    background: #144E82;
    color: #fff;
  }

  header.act {
    background: #fff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  }

  header.act #logo {
    /* background:#fff; */
    margin-top: 20px;
  }

  header.act #logo img {
    opacity: 0;
  }

  header.act #logo img.act {
    opacity: 1;
  }

  header.act nav a {
    color: #777777;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  header.act nav a:hover {
    background: #144E82;
    color: #fff;
  }

  header.act nav a.act {
    background: #144E82;
    color: #fff;
  }

  header.act nav ul ul {
    top: 69px;
  }

  header.act nav ul ul li a {
    margin: 0px;
  }

  .mob-icon {
    display: none;
  }

  @media (min-width: 1200px) {
    nav ul ul {
      width: auto;
      left: 50%;
      margin-left: 6px;
      transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
    }

    nav ul li:last-child ul {
      margin-left: -25px;
    }

    nav ul ul li {
      padding: 0;
      width: auto;
      float: none;
    }

    nav ul ul li a {
      width: auto;
      float: none;
      display: block;
      left: 0;
      width: 185px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  /*header type-2 (attached to bottom)*/
  @media (min-width: 1200px) {
    body {
      overflow-x: hidden;
    }

    header.bottom-fixed {
      top: 100vh;
      position: absolute;
      margin-top: -75px;
    }

    header.bottom-fixed ul ul {
      top: auto !important;
      bottom: 69px;
    }

    header.bottom-fixed nav .submeny a:after {
      top: -7px;
      transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
    }

    header.default-act {
      -moz-transition: none;
      -o-transition: none;
      -webkit-transition: none;
      transition: none;
      -ms-transition: none;
    }
  }

  @media (max-width: 1100px) {
    #logo {
      margin-left: 20px;
      margin-top: 10px;
    }

    .mob-icon {
      display: block;
      position: relative;
      float: right;
      margin-right: 20px;
      height: 40px;
      cursor: pointer;
    }

    .mob-icon span {
      position: relative;
      float: left;
      width: 40px;
      height: 4px;
      background: #144E82;
      margin-top: 34px;
    }

    .mob-icon span:before {
      position: absolute;
      left: 0px;
      top: -8px;
      content: "";
      width: 40px;
      height: 4px;
      background: #144E82;
    }

    .mob-icon span:after {
      position: absolute;
      left: 0px;
      top: 8px;
      content: "";
      width: 40px;
      height: 4px;
      background: #144E82;
    }

    .mob-icon.act span {
      width: 30px;
    }

    .mob-icon.act span:before {
      width: 30px;
      top: -2px;
    }

    .mob-icon.act span:after {
      width: 30px;
      top: 2px;
    }

    nav {
      padding-right: 0px;
      float: none;
      position: absolute;
      top: 65px;
      left: -100%;
      height: 100%;
      width: 100%;
      background: #000;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -moz-overflow-scrolling: touch;
      -o-overflow-scrolling: touch;
      -ms-overflow-scrolling: touch;
    }

    nav a {
      width: 100%;
      padding: 0px;
      margin: 0px !important;
      text-indent: 20px;
      font-size: 18px;
      color: #fff !important;
    }

    nav ul li:last-child {
      margin-bottom: 65px !important;
    }

    nav ul li {
      width: 100%;
      padding: 0px;
    }

    nav ul ul {
      position: relative;
      float: left;
      top: 0px;
    }

    nav ul ul li a {
      text-align: left;
      width: 100%;
      left: 0px;
      text-indent: 30px;
      font-size: 16px !important;
      color: #777 !important;
      opacity: 0;
    }

    nav ul ul li a:hover {
      background: #fff;
    }

    nav ul ul li:last-child {
      margin-bottom: 0px !important;
    }

    nav .submeny a:after {
      bottom: 13px;
    }

    nav .submeny:hover ul li a {
      opacity: 1;
    }

    header.act nav ul ul {
      top: 0px;
    }

    header.act nav ul ul li {
      margin-bottom: 0px !important;
    }

    header {
      background: #fff;
      height: 65px;
    }

    header #logo img {
      opacity: 0;
    }

    header #logo img.act {
      opacity: 1;
    }

    header.act-mob {
      height: 100%;
    }

    header.act-mob nav {
      left: 0px;
    }


  }

  @media (max-width: 991px) {}

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 06 - FOOTER */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  footer {
    background: #1b1b1b;
    position: relative;
  }

  .subscribe {
    position: relative;
    width: 100%;
    padding: 50px 0px;
    text-align: center;
    font-size: 0px;
    border-bottom: 1px solid #000;
  }

  .subscribe-text {
    position: relative;
    display: inline-block;
    color: #b4b4b4;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    padding-right: 30px;
    vertical-align: middle;
    height: 45px;
    line-height: 45px;
  }

  .subscribe form {
    float: none;
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .subscribe form input[type="email"] {
    width: 560px;
    background: #144E82;
    height: 45px;
    line-height: 45px;
    padding: 0px 25px;
    margin: 0px;
    position: relative;
    float: left;
    color: #dddddd;
  }

  .subscribe form ::-webkit-input-placeholder {
    color: #dddddd;
  }

  .subscribe form :-moz-placeholder {
    color: #dddddd;
    opacity: 1;
  }

  .subscribe form ::-moz-placeholder {
    color: #dddddd;
    opacity: 1;
  }

  .subscribe form :-ms-input-placeholder {
    color: #dddddd;
  }


  .footer-bottom {
    position: relative;
    width: 100%;
    padding: 50px 0px;
    text-align: center;
    font-size: 0px;
  }

  .footer-bottom .footer-linck {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .footer-bottom .footer-linck a {
    position: relative;
    float: left;
    margin: 0px 14px;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
  }

  .footer-bottom .footer-linck a:hover {
    color: #144E82;
  }

  .footer-bottom .media-icon {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    vertical-align: middle;
  }

  .footer-bottom .media-icon a {
    margin: 0px 8px;
    width: 35px;
    height: 35px;
    position: relative;
    float: left;
  }

  .footer-bottom .media-icon a img {
    position: relative;
    float: left;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    margin-left: -8px;
  }

  .footer-bottom .media-icon a:after {
    position: absolute;
    left: 0px;
    top: 0px;
    background: rgba(255, 255, 255, 0);
    content: "";
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }

  .footer-bottom .media-icon a:hover:after {
    background: rgba(255, 255, 255, 0.1);
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }

  .copy {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  .copy span {
    color: #b4b4b4;
    font-size: 14px;
  }

  @media (max-width: 991px) {}

  @media (max-width: 767px) {
    .subscribe form input[type="email"] {
      width: 320px;
    }

    .footer-bottom .footer-linck a {
      width: 50%;
      margin: 15px 0px;
    }

    .footer-bottom .media-icon {
      margin-top: 40px;
      padding-left: 0px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 07 - SIMPLE BLOCK STRUCTURE */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .blocks-container {
    position: relative;
    width: 100%;
  }

  .block {
    position: relative;
    width: 100%;
  }


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 08 - BLOCK "TYPE 1" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-1 {
    height: 100vh;
    /* min-height: 600px; */
  }

  .block.type-1.type-1-1 {
    height: auto;
    min-height: 0px;
  }

  .block.type-1.type-1-1 .clip {
    height: 78%;
  }

  .numbers {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }

  .numbers .col-md-3 {
    text-align: center;
    cursor: pointer;
  }

  .numbers .col-md-3:before {
    position: absolute;
    left: 0px;
    bottom: -125px;
    background: #fff;
    width: 100%;
    height: 125px;
    content: "";
    z-index: 0;
  }

  .numbers .col-md-3:after {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 1px;
    background: rgba(255, 255, 255, 0.3);
    content: "";
  }

  .numbers .col-md-3:last-child:after {
    display: none;
  }

  .numbers .thumbs-image {
    position: absolute;
    left: 0px;
    bottom: -125px;
    width: 100%;
    opacity: 0;
    z-index: -1;
    backface-visibility: hidden;
  }

  .numbers .num {
    display: block;
    width: 100%;
    color: #144E82;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    padding: 25px 0px;
    position: relative;
  }

  .numbers .num-text {
    display: block;
    width: 100%;
    color: #dcdcdc;
    font-size: 14px;
    text-transform: uppercase;
    padding-bottom: 40px;
    position: relative;
  }

  .type-1-text {
    height: 100%;
    position: relative;
  }

  .type-1-text h1,
  .type-1-text h2 {
    color: #fff;
  }

  .type-1-text p {
    color: #dddddd;

  }

  .type-1-text .row {
    height: 100%;
  }

  .type-1-text .type-1-center {
    text-align: center;
    height: 100%;
    display: table;
    vertical-align: middle;
  }

  .type-1-text .button {
    margin: 0px 15px;
    margin-bottom: 60px;
  }

  .type-1-text article {
    margin-top: 145px;
  }

  .type-1-text article.table {
    display: table-cell;
    vertical-align: middle;
    float: none;
    margin-top: 0px;
  }

  .marvel-device-wrap {
    position: relative;
    display: inline-block;
    text-align: center;
  }

  .marvel-device.iphone6plus {
    height: 853px;
    transform: scale(0.65);
    -moz-transform: scale(0.65);
    -o-transform: scale(0.65);
    -ms-transform: scale(0.65);
    -webkit-transform: scale(0.65);
    position: relative;
    float: left;
    display: block;
    margin-top: -125px;
  }

  .marvel-device-wrap .iphone-image-wrap {
    position: absolute;
    left: 50%;
    top: 95px;
    margin-left: -118px;
    height: 412px;
    overflow: hidden;
  }

  .marvel-device-wrap .iphone-image-wrap-move {
    position: relative;
    float: left;
    -webkit-animation: magic-move 7s infinite;
    animation: magic-move 7s infinite;
  }

  .marvel-device-wrap .iphone-image-wrap img {
    position: relative;
    float: left;
  }

  #content-wrapper .parallax-bg-text * {
    color: #fff;
  }

  #content-wrapper .parallax-bg-text p,
  #content-wrapper .parallax-bg-text ul li,
  #content-wrapper .parallax-bg-text .page-tagline .description {
    color: rgba(255, 255, 255, 0.8);
  }

  @media (min-width: 991px) {
    .numbers .col-md-3:hover:before {
      bottom: 0px;
    }

    .numbers .col-md-3:hover .num-text {
      color: #555;
    }

    .numbers .col-md-3:hover .thumbs-image {
      width: 100%;
      left: 0px;
      bottom: 125px;
      opacity: 1;
      z-index: 1;
    }
  }

  @media (max-width: 991px) {
    .block.type-1 {
      height: auto;
      min-height: 0px;
    }

    .type-1-text .button {
      margin-bottom: 10px;
    }

    .numbers {
      position: relative;
      background: #a39b90;
    }

    .type-1-text {
      padding-top: 60px;
    }

    .block.type-1.type-1-1 .type-1-text {
      padding-top: 0px;
    }

    .block.type-1.type-1-1 .type-1-text article {
      margin-top: 65px;
    }

    .numbers .num-text {
      padding-bottom: 20px;
    }

    .numbers .col-md-3 {
      padding-top: 40px;
      padding-bottom: 17px;
    }

    .numbers .col-md-3:before {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .type-1-text p {
      padding-bottom: 20px;
    }

    .marvel-device-wrap {
      display: none;
    }

    .block.type-1.type-1-1 .clip {
      height: 100%;
    }

    .block.type-1.type-1-1 .type-1-text article {
      margin-top: 65px;
      margin-bottom: 20px;
    }

    .type-1-text .type-1-center {
      overflow: hidden;
    }
  }

  @keyframes magic-move {
    0% {
      margin-top: 0px;
    }

    14% {
      margin-top: 0px;
    }

    28% {
      margin-top: -412px;
    }

    42% {
      margin-top: -412px;
    }

    56% {
      margin-top: -824px;
    }

    70% {
      margin-top: -824px;
    }

    84% {
      margin-top: 0px;
    }

    100% {
      margin-top: 0px;
    }
  }

  @-webkit-keyframes magic-move {
    0% {
      margin-top: 0px;
    }

    14% {
      margin-top: 0px;
    }

    28% {
      margin-top: -412px;
    }

    42% {
      margin-top: -412px;
    }

    56% {
      margin-top: -824px;
    }

    70% {
      margin-top: -824px;
    }

    84% {
      margin-top: 0px;
    }

    100% {
      margin-top: 0px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 09 - BLOCK "TYPE 2" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-2 {
    overflow: hidden;
  }

  .block.type-2 .image-block {
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 50%;
    background-size: cover;
    background-position: center top;
    z-index: 1;
  }

  @media(max-width:600px) {
    .block.type-2 .image-block {
      width: 100%;
      position: inherit;
      height: 400px;
    }
  }

  .block.type-2 .image-block:first-child {
    left: 0;
    right: auto;
  }

  .block.type-2 .col-md-6 {
    overflow: hidden;
    height: 700px;
  }

  .block.type-2 .col-md-6 img {
    position: relative;
    float: left;
    width: 100%;
  }

  .block.type-2 .col-md-4 {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .block.type-2 article {
    display: table-cell;
    vertical-align: middle;
    float: none;
    height: 700px;
  }

  .block.type-2 .col-md-4.col-md-pull-6 {
    min-height: 900px;
  }

  .block.type-2 .col-md-6.col-md-push-6 {
    min-height: 900px;
    z-index: 1;
  }

  /*.block.type-2 .col-md-4:first-child article:nth-child(1){height: auto; padding-top: 120px; padding-bottom: 73px;}*/
  .block.type-2 .col-md-4 article.normall {
    height: auto;
    padding-top: 120px;
    padding-bottom: 73px;
  }

  .block.type-2 article.normall:last-child {
    height: 700px;
  }

  .block.type-2 .image-block .bg {
    width: 50%;
    left: 50%;
  }

  .block.type-2 .image-block:first-child .bg {
    width: 50%;
    left: 0;
  }

  .mouse-icon {
    width: 22px;
    height: 38px;
    position: absolute;
    left: 50%;
    margin-left: -11px;
    bottom: 30px;
    /* background: url(../img/mouse.png); */
    cursor: pointer;
  }

  .mouse-icon:before {
    width: 4px;
    height: 12px;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    top: 8px;
    content: "";
    border-radius: 2px;
    -webkit-border-radius: 2px;
    background: #144E82;
  }

  @media (max-width: 991px) {
    .block.type-2 article {
      height: auto;
      padding: 30px 0px;
      padding-top: 35px;
    }

    .block.type-2 .col-md-6 {
      height: 150px;
      display: none;
    }

    /*.block.type-2 .col-md-4:first-child article:nth-child(1){height: auto; padding-bottom: 0px; padding-top: 35px;}*/
    .block.type-2 .col-md-4 article.normall,
    .block.type-2 article.normall:last-child {
      height: auto;
      padding-bottom: 0px;
      padding-top: 35px;
    }

    .block.type-2 .col-md-4.col-md-pull-6 {
      min-height: 0px;
    }

    .mouse-icon {
      display: none;
    }
  }

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 10 - BLOCK "TYPE 3" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-3 {
    padding-top: 60px;
  }

  .block.type-2 .block.type-3 {
    padding-bottom: 15px;
  }

  .block.type-3:before {
    position: absolute;
    left: -1000px;
    bottom: 0px;
    width: 2000px;
    background: #f2f2f2;
    height: 1px;
    content: "";
  }

  .block.type-3:after {
    position: absolute;
    left: -1000px;
    top: 0px;
    width: 2000px;
    background: #f2f2f2;
    height: 1px;
    content: "";
  }

  .block.type-3 img {
    float: left;
    width: 30px;
    width: 30px;
  }

  .block.type-3 article {
    float: none;
    position: static;
    height: auto;
    padding-left: 20px;
    padding-top: 6px;
  }

  .block.type-3 .col-md-12 {
    margin: 10px 0px;
  }

  .block.type-3.type-3-1 {
    padding-top: 0px;
    margin-top: -107px;
    padding-bottom: 75px;
    float: left;
    width: 100%;
    z-index: 1;
    background: #fff;
  }

  .block.type-3.type-3-1 img,
  .new-block.type-3.type-3-1 img,
  .new-block .little-img-text-entry img {
    width: 50px;
    height: 50px;
    margin-top: 45px;
    float: left;
  }

  .icons-at-the-top {
    text-align: center;
  }

  #content-wrapper .icons-at-the-top img {
    float: none;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .block.type-3.type-3-1 article,
  .new-block.type-3.type-3-1 article,
  .new-block .little-img-text-entry article {
    padding-left: 100px;
  }

  .new-block.type-3.type-3-1 .icons-at-the-top article {
    padding-left: 0;
  }

  .block.type-3.type-3-1 .col-md-4 {
    margin-top: 17px;
  }

  .block.type-3.type-3-1:after,
  .block.type-3.type-3-1:before {
    display: none;
  }

  @media (max-width: 991px) {
    .block.type-3 {
      padding-top: 30px;
    }

    .block.type-2 .block.type-3 {
      padding-bottom: 0px;
    }

    .block.type-3 .col-md-12:nth-child(3) {
      margin-bottom: 5px;
    }

    .block.type-3 .col-md-12:nth-child(3) article p {
      padding-bottom: 0px;
    }

    .block.type-3.type-3-1 {
      padding-bottom: 0px;
    }
  }

  @media (max-width: 767px) {
    .block.type-3.type-3-1 {
      margin-top: 40px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 11 - BLOCK "TYPE 4" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-4 {
    padding: 120px 0px;
    padding-top: 120px;
    padding-bottom: 73px;
    background: #fbfbfb;
  }

  .block.type-4.type-4-1 {
    padding-top: 120px;
    padding-bottom: 90px;
  }

  .block.type-4 .h2 {
    margin-bottom: 20px !important;
  }

  .block.type-4.type-4-1 .h2 {
    margin-bottom: 40px !important;
  }

  .block.type-4 .h3 {
    margin-bottom: 20px !important;
    text-transform: none;
  }

  .block.type-4 .col-md-3 article {
    margin: 20px 0px;
  }

  .block.type-4.type-4-1 .col-md-3 article:nth-child(1) {
    margin-bottom: 40px;
  }

  @media (max-width: 991px) {
    .block.type-4 {
      padding: 40px 0px;
      padding-bottom: 0px;
    }

    .block.type-4.type-4-1 {
      padding-top: 36px;
      padding-bottom: 22px;
    }
  }

  @media (max-width: 767px) {
    .col-md-3.col-md-offset-1.col-sm-12 .small.col-md-12.col-sm-6 {
      padding: 0px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 12 - BLOCK "TYPE 5" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-5 {
    padding-top: 120px;
    padding-bottom: 78px;
  }

  .block.type-5:after {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: #f2f2f2;
    height: 1px;
    content: "";
  }

  .block.type-5 .img-wrap-slider {
    position: relative;
    float: left;
    overflow: hidden;
    height: 700px;
  }

  .block.type-5 .img-wrap {
    position: relative;
    float: left;
    width: 100%;
    height: 700px;
    text-align: center;
  }

  .block.type-5 .img-wrap-move {
    position: relative;
    float: left;
    width: 100%;
  }

  .block.type-5 .img-wrap img {
    display: inline-block;
    max-height: 90%;
    width: auto;
  }

  .block.type-5 .pagination {
    width: 10px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    margin-top: -2px;
  }

  .block.type-5 .swiper-container {
    padding-left: 32px;
    margin-left: -32px;
  }

  .block.type-5 .swiper-container:after {
    position: absolute;
    left: 0px;
    top: 0px;
    background: #fff;
    content: "";
    width: 32px;
    height: 100%;
  }

  .block.type-5 .register-login {
    padding-top: 70px;
    margin-top: 20px;
  }

  .block.type-5 .register-login:after {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 1px;
    content: "";
    background: #f2f2f2;
  }

  .block.type-5 .register-login:before {
    position: absolute;
    left: -32px;
    top: 0px;
    width: 100%;
    height: 1px;
    content: "";
    background: #f2f2f2;
  }

  .block.type-5 h5 {
    text-transform: none;
  }

  .triple-images-container {
    position: relative;
    text-align: center;
    max-width: 570px;
  }

  .triple-images-container img {}

  .triple-images-container img:nth-child(1) {
    position: relative;
    width: 50%;
    height: auto;
    display: inline-block;
    vertical-align: bottom;
    z-index: 2;
  }

  .triple-images-container img:nth-child(2) {
    position: absolute;
    left: 0;
    width: 40%;
    top: 10%;
  }

  .triple-images-container img:nth-child(3) {
    position: absolute;
    right: 0;
    width: 33%;
    top: 18%;
  }

  @media (max-width: 1170px) {
    .block.type-5 .register-login {
      padding-top: 38px;
    }

    .block.type-5 .register-login article {
      width: 90%;
    }

    .block.type-5 {
      padding: 40px 0px;
    }

    .block.type-5 .col-md-5 p {
      padding-bottom: 13px;
    }

    .block.type-5 .register-login .swiper-wrapper {
      padding-bottom: 20px;
    }
  }

  @media (max-width: 991px) {
    .block.type-5 .img-wrap-slider {
      display: none;
    }

    .block.type-5 .pagination {
      width: 100%;
      top: auto;
      bottom: 0px;
    }

    .block.type-5 .pagination .swiper-active-switch {
      width: 20px;
      height: 9px;
    }
  }

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 13 - BLOCK "TYPE 6" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-6 {
    background: #144E82;
    padding-top: 120px;
    padding-bottom: 112px;
  }

  .block.type-6 h2 {
    color: #fff;
  }

  .block.type-6 p {
    color: rgba(255, 255, 255, 0.8);
  }

  .block.type-6 .titel-left:after {
    background: #fff;
  }

  .block.type-6 .touru-thumbs {
    position: relative;
    float: left;
    width: 33.33%;
  }

  .block.type-6 .touru-thumbs img {
    width: 90%;
    position: relative;
    float: left;
    margin: 5px 5%;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.30);
  }

  .block.type-6 .pagination {
    margin-top: 40px;
  }

  .block.type-6 .swiper-pagination-switch {
    background: rgba(255, 255, 255, 0.3);
  }

  .block.type-6 .swiper-active-switch {
    height: 9px;
    width: 23px;
    background: #fff;
  }

  @media (max-width: 991px) {
    .touru {
      margin-top: 40px;
    }

    .block.type-6 {
      padding-top: 40px;
      padding-bottom: 38px;
    }
  }

  @media (max-width: 767px) {}

  @media (max-width: 500px) {
    .block.type-6 .touru-thumbs {
      width: 100%;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 14 - BLOCK "TYPE 7" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .block.type-7 {
    padding-top: 120px;
    padding-bottom: 120px;
    background: #fff;
    -webkit-backface-visibility: hidden;
  }

  .block.type-7 h2 {
    text-align: center;
  }

  .block.type-7 p {
    text-align: center;
  }

  .block.type-7 form {
    margin-top: 8px;
  }

  .block.type-7 .contact-thumbs {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 24px;
  }

  .block.type-7 .contact-thumbs img {
    padding-bottom: 15px;
  }

  .block.type-7 .contact-thumbs a {
    color: #777777;
    font-weight: bold;
  }

  .block.type-7 .page-tagline *:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1199px) {}

  @media (max-width: 991px) {
    .block.type-7 {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 15 - BLOCK "TYPE 8" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block {
    padding: 120px 0;
    border-bottom: 1px #f2f2f2 solid;
    color: #fff;
    background: #fff;
    margin-top: 0;
    position: relative;
    -webkit-backface-visibility: hidden;
  }

  .new-block.color-background,
  .new-block.parallax-bg-text {
    border: none;
  }

  .new-block .new-block {
    padding: 0;
    border: none;
  }

  /* .new-block article *:last-child {
      padding-bottom: 0 !important;
  } */

  /* .new-block *:last-child {
      margin-bottom: 0 !important;
  } */

  .new-block article {
    float: none;
    width: auto;
  }

  .new-block.type-3 {
    background: #fbfbfb;
  }

  .new-block.color-background {
    background: #144E82;
    color: #144E82;
  }

  .new-block.color-background article *,
  .new-block.color-background .titel-left {
    color: #fff;
  }

  .new-block.color-background ul li,
  .new-block.color-background p,
  .new-block.color-background .page-tagline .description {
    color: rgba(255, 255, 255, 0.8);
  }

  .new-block.color-background ul li {
    font-size: 14px;
  }

  /**/
  .new-block .pagination {
    width: 9px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    z-index: 1;
    margin-top: 0;
  }

  .new-block .pagination .swiper-pagination-switch {
    margin: 5px 0;
  }

  .new-block.color-background .swiper-pagination-switch,
  .new-block.parallax-bg-text .swiper-pagination-switch {
    background: rgba(255, 255, 255, 0.3);
  }

  .new-block.color-background .swiper-active-switch,
  .new-block.parallax-bg-text .swiper-active-switch {
    background: #fff;
  }

  .new-block.color-background .titel-left:after {
    background: #fff;
  }

  .new-block .swiper-container {
    margin-left: -32px;
  }

  .new-block .swiper-container .paddings-container {
    padding-left: 52px;
  }

  .new-block .swiper-container:before {
    position: absolute;
    width: 12px;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    background: red;
    z-index: 1;
    background: currentColor;
  }

  .new-block .swiper-container.horizontal-pagination:before {
    display: none;
  }

  .new-block .swiper-container.horizontal-pagination {
    margin-left: 0;
  }

  .new-block .swiper-container.horizontal-pagination .pagination {
    width: auto;
    position: relative;
    margin-top: 26px;
    transform: translateY(0%);
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
  }

  .new-block .swiper-container.horizontal-pagination .pagination .swiper-pagination-switch {
    margin: 0 10px;
    text-align: center;
  }

  .new-block .swiper-container.horizontal-pagination .pagination .swiper-active-switch {
    width: 23px;
    height: 9px;
  }

  .new-block .swiper-container.horizontal-pagination .paddings-container {
    padding-left: 0;
  }

  /**/
  .new-block.type-8 .normall {
    margin-bottom: 60px;
  }

  .new-block.type-8 .tabs-title {
    font-size: 20px;
    color: #222;
    line-height: 40px;
    margin-bottom: 15px !important;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
  }

  .icon-text-description-entry {
    margin-bottom: 60px;
  }

  .icon-text-description-entry img {
    float: left;
    width: 30px;
    height: auto;
    margin-top: -4px;
  }

  .icon-text-description-entry article {
    margin-left: 50px;
  }

  .phones-container {
    text-align: center;
  }

  .phones-container img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  /**/
  .new-block .little-img-text-entry {
    padding-bottom: 57px;
  }

  .new-block .little-img-text-entry:nth-last-child(1),
  .new-block .little-img-text-entry:nth-last-child(2),
  .new-block .little-img-text-entry:nth-last-child(3) {
    padding-bottom: 0;
  }

  .little-img-text-entry:nth-child(3n+1) {
    clear: both;
  }

  /**/
  .page-tagline {
    text-align: center;
    margin-bottom: 60px;
    padding-top: 25px;
  }

  .page-tagline .title {
    font-family: 'black';
    font-size: 46px;
    color: #222;
    line-height: 40px;
    text-transform: uppercase;
    padding-bottom: 40px;
    position: relative;
  }

  .page-tagline .title:last-child {
    padding-bottom: 0;
  }

  .page-tagline .title:before {
    width: 30px;
    height: 5px;
    background: #144E82;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    top: -25px;
    content: "";
  }

  .page-tagline .description {
    font-size: 16px;
    line-height: 28px;
    color: #777;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 40px;
  }

  .color-background .page-tagline .title {
    color: #fff;
  }

  .color-background .page-tagline .title:before {
    background: #fff;
  }

  .color-background .page-tagline .description {
    color: #fff;
  }

  @media (max-width: 991px) {
    .new-block {
      padding: 50px 0;
    }

    .page-tagline,
    .blog-container .page-tagline {
      margin-bottom: 40px;
    }

    .little-img-text-entry:nth-child(3n+1) {
      clear: none;
    }

    .little-img-text-entry:nth-child(2n+1) {
      clear: both;
    }

    .new-block .little-img-text-entry,
    .new-block .little-img-text-entry:nth-last-child(3) {
      padding-bottom: 40px;
    }

    .new-block .little-img-text-entry:nth-last-child(1),
    .new-block .little-img-text-entry:nth-last-child(2) {
      padding-bottom: 0;
    }

    .new-block .swiper-container {
      margin-left: 10px;
    }

    .new-block .swiper-container.horizontal-pagination {
      margin-left: 0;
    }

    .new-block .swiper-container .paddings-container {
      padding-right: 10px;
    }

    .new-block .swiper-container.horizontal-pagination .paddings-container {
      padding-right: 0;
    }
  }

  @media (max-width: 767px) {
    .phones-container {
      width: auto;
      left: 0;
    }

    .new-block .content-entry:first-child {
      padding-bottom: 30px;
    }

    .new-block .little-img-text-entry,
    .new-block .little-img-text-entry:nth-last-child(2) {
      padding-bottom: 40px;
    }

    .new-block.type-3.type-3-1 img {
      margin-top: 0;
    }

    /*pagination turned to default horizontal*/
    .new-block .swiper-container:before {
      display: none;
    }

    .new-block .swiper-container {
      margin-left: 0;
    }

    .new-block .swiper-container .pagination {
      width: auto;
      position: relative;
      margin-top: 30px;
      transform: translateY(0%);
      -moz-transform: translateY(0%);
      -webkit-transform: translateY(0%);
      -ms-transform: translateY(0%);
    }

    .new-block .swiper-container .pagination .swiper-pagination-switch {
      margin: 0 10px;
      text-align: center;
    }

    .new-block .swiper-container .pagination .swiper-active-switch {
      width: 23px;
      height: 9px;
    }

    .new-block .swiper-container .paddings-container {
      padding-left: 0;
    }

    /**/
    .page-tagline .title {
      font-size: 38px;
      line-height: 35px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 16 - BLOCK "TYPE 9" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-9 .h3 {
    text-transform: none;
    padding-bottom: 15px;
  }

  .new-block.type-9 .cell-view {
    width: 1000px;
    height: 495px;
  }

  .swiper-image-block {
    text-align: center;
  }

  .swiper-image-block img {
    max-width: 410px;
    height: auto;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
  }



  .list-style-1 li:after {
    display: none;
  }

  .list-style-2 li:after {
    display: none;
  }

  @media (max-width: 991px) {
    .swiper-image-block {
      padding-top: 30px;
    }

    .new-block.type-9 .cell-view {
      height: auto;
    }
  }

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 17 - BLOCK "TYPE 10" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-10 .paddings-container {
    padding: 0 15px !important;
    text-align: center;
  }

  .new-block.type-10 .image-wrapper {
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 24px;
    max-width: 370px;
    position: relative;
    overflow: hidden;
  }

  .new-block.type-10 .image-wrapper .thumbnail-image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    left: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  .new-block.type-10 .image-wrapper:hover .thumbnail-image {
    left: 70px;
  }

  .new-block.type-10 .image-socials-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 100%;
    background: #144E82;
  }

  .new-block.type-10 .image-socials-box a {
    padding: 15px;
    display: block;
    position: relative;
    left: -50px;
  }

  .new-block.type-10 .image-wrapper:hover .image-socials-box a {
    left: 0;
  }

  .new-block.type-10 .image-socials-box img {
    width: 16px;
    height: 16px;
  }

  .new-block.type-10 .image-wrapper .image-socials-box a:hover img {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
  }

  .new-block.type-10 .image-socials-box .vertical-align {
    width: 46px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
  }

  .new-block.type-10 .paddings-container .name {
    font-size: 16px;
    color: #222;
    text-transform: uppercase;
    line-height: 16px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
  }

  .new-block.type-10 .paddings-container .position {
    font-size: 14px;
    line-height: 24px;
    color: #144E82;
    margin-bottom: 15px;
  }

  .new-block.type-10 .paddings-container .description {
    font-size: 14px;
    line-height: 24px;
    color: #777;
    padding: 0 35px;
  }

  @media (max-width: 991px) {}

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 18 - BLOCK "TYPE 11" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-11 {
    min-height: 650px;
    height: 100vh;
    padding: 0;
  }

  .new-block.type-11 .container {
    position: relative;
  }

  .new-block.type-11 .swiper-container {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;
  }

  @media (max-width: 1199px) {
    .new-block.type-11 .swiper-container {
      position: absolute;
    }
  }

  @media (max-height: 650px) {
    .new-block.type-11 .swiper-container {
      position: absolute;
    }
  }

  .new-block.type-11 .swiper-slide {
    position: relative;
    background-position: center center;
    background-size: cover;
  }

  .new-block.type-11 .swiper-slide:before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: "";
    background: rgba(0, 0, 0, 0.35);
  }

  .new-block.type-11 .swiper-container,
  .new-block.type-11 .swiper-wrapper,
  .new-block.type-11 .swiper-slide {
    height: inherit !important;
    min-height: inherit !important;
  }

  .new-block.type-11 .center-tagline {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    margin-top: -32px;
  }

  .new-block.type-11.increased-height .center-tagline {
    margin-top: 32px;
  }

  .new-block.type-11 .center-tagline .title {
    font-size: 60px;
    line-height: 70px;
    color: #fff;
    font-family: 'black';
    text-transform: uppercase;
    padding-bottom: 20px;
  }

  .new-block.type-11 .center-tagline .description {
    font-size: 18px;
    line-height: 30px;
    color: #ddd;
    margin-bottom: 40px;
  }

  .banner-tabs {
    height: 120px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .banner-tabs .entry {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }

  .banner-tabs .entry span.title {
    display: block;
    color: #144E82;
    padding-top: 25px;
    margin-bottom: 10px;
    position: relative;
  }

  .banner-tabs span.title:before {
    height: 3px;
    width: 50%;
    background: #144E82;
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    content: "";
  }

  .banner-tabs .entry.active span.title:before,
  .banner-tabs .entry:hover span.title:before {
    width: 100%;
  }

  .banner-tabs .entry span.description {
    display: block;
    color: #ddd;
  }

  .banner-tabs .entry:hover span.title {
    color: #ddd;
  }

  .banner-tabs .entry:hover span.description {
    color: #144E82;
  }

  .new-block.type-11 .swiper-container.horizontal-pagination .pagination {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 15px;
    top: auto;
    margin: 0;
    display: none;
  }

  .new-block.type-11 .banner-image {
    position: static;
  }

  .new-block.type-11 .banner-image img {
    max-height: 550px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
  }

  .new-block.type-11 .banner-image:first-child img {
    left: 25%;
  }

  .banner-text.text-align-right {
    text-align: right;
  }

  .banner-text.text-align-center {
    text-align: center;
  }

  .banner-text {
    position: relative;
    opacity: 0;
    transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
  }

  .banner-image {
    position: relative;
    opacity: 0;
  }

  .swiper-slide-active .banner-text {
    opacity: 1;
    transform: translateX(0);
    -moz-transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    left: 0;
  }

  .swiper-slide-active .banner-image {
    opacity: 1;
  }

  @media (max-width: 1400px) {
    .new-block.type-11 .default-arrow.left {
      left: 30px;
    }

    .new-block.type-11 .default-arrow.right {
      right: 30px;
    }
  }

  @media (max-width: 1300px) {
    .new-block.type-11 .default-arrow.left {
      left: 0;
    }

    .new-block.type-11 .default-arrow.right {
      right: 0;
    }
  }

  @media (max-width: 1199px) {
    .new-block.type-11 .banner-image img {
      max-height: 450px;
    }

    .new-block.type-11.reduced-height .center-tagline {
      margin-top: 32px;
    }

    .new-block.type-11 .swiper-slide .container {
      max-width: 900px;
    }
  }

  @media (max-width: 991px) {
    .banner-tabs {
      display: none;
    }

    .new-block.type-11 .swiper-container.horizontal-pagination .pagination {
      display: block;
    }

    .banner-text.text-align-right,
    .banner-text.text-align-center {
      text-align: left;
    }

    .new-block.type-11 .banner-image {
      display: none;
    }

    .new-block.type-11 {
      height: 600px;
      min-height: 0;
    }

    .new-block.type-11 .center-tagline {
      margin-top: 30px;
    }
  }

  @media (max-width: 767px) {
    .new-block.type-11 {
      height: 500px;
    }

    .new-block.type-11 .center-tagline .title {
      font-size: 32px;
      line-height: 36px;
    }

    .new-block.type-11 .center-tagline .description {
      margin-bottom: 20px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 19 - BLOCK "TYPE 12" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-12 {
    overflow: hidden;
  }

  .new-block.type-12 .normall {
    margin-bottom: 60px;
  }

  .logos-slider {
    margin-bottom: 60px;
  }

  .logos-slider .pagination {
    display: none;
  }

  .logos-slider .swiper-slide {
    text-align: center;
    position: relative;
  }

  .logos-slider .swiper-slide:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    content: "";
    background: rgba(255, 255, 255, 0.2);
  }

  .logos-slider .swiper-slide:first-child:before {
    display: none;
  }

  .logos-slider .swiper-slide img {
    display: inline-block;
    vertical-align: bottom;
  }

  .testimonials-slider {
    margin-bottom: -135px;
    text-align: center;
    position: relative;
  }

  .testimonials-slider .pagination {
    display: none;
  }

  .testimonials-slider img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  .testimonials-slider .blockquote-wrapper p {
    color: #777;
    font-size: 18px;
    line-height: 32px;
  }


  .testimonials-slider .blockquote-wrapper footer {
    background: none;
    font-size: 16px;
    line-height: 28px;
    color: #777;
    margin-top: 15px;
  }

  .testimonials-slider .blockquote-wrapper footer cite {
    font-family: 'Poppins', sans-serif;
  }

  .testimonials-slider .blockquote-wrapper .cite {
    color: #222;
  }

  blockquote *:before {
    display: none;
  }

  .testimonials-slider .cell-view {
    height: 440px;
  }

  .testimonials-slider .swiper-arrow {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    text-align: center;
    cursor: pointer;
  }

  .testimonials-slider .swiper-arrow:hover {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
  }

  .testimonials-slider .swiper-arrow.left {
    left: 50%;
    margin-left: -600px;
  }

  .testimonials-slider .swiper-arrow.right {
    right: 50%;
    margin-right: -600px;
  }

  .testimonials-slider .swiper-arrow .glyphicon {
    color: #fff;
    font-size: 20px;
    line-height: 32px;
  }

  .testimonials-text {
    position: relative;
    opacity: 0;
    transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
  }

  .testimonials-image {
    position: relative;
    opacity: 0;
    transform: translateY(300px);
    -moz-transform: translateY(300px);
    -webkit-transform: translateY(300px);
    -ms-transform: translateY(300px);
  }

  .swiper-slide-active .testimonials-text,
  .swiper-slide-active .testimonials-image {
    opacity: 1;
    transform: translateX(0) translateY(0);
    -moz-transform: translateX(0) translateY(0);
    -webkit-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
  }

  @media (max-width: 1199px) {
    .testimonials-slider .swiper-arrow.left {
      left: 50%;
      margin-left: -500px;
    }

    .testimonials-slider .swiper-arrow.right {
      right: 50%;
      margin-right: -500px;
    }
  }

  @media (max-width: 991px) {
    .testimonials-slider {
      margin-bottom: 0;
    }

    .testimonials-slider .cell-view {
      height: auto;
    }

    .new-block.type-12 .normall {
      margin-bottom: 40px;
    }

    .testimonials-slider .swiper-arrow {
      display: none;
    }

    .testimonials-slider .pagination {
      display: block;
    }

    .logos-slider .pagination {
      display: block;
    }
  }

  @media (max-width: 767px) {
    .testimonials-slider .blockquote-wrapper {
      margin-top: 18px;
      padding: 35px 20px 20px 20px;
      background-position: 20px 15px;
      line-height: 24px;
    }

    .testimonials-slider .blockquote-wrapper footer {
      line-height: 24px;
    }

    .testimonials-slider .blockquote-wrapper:before {
      /* background: url(../img/blockquote-triangle-rotated.png); */
      width: 28px;
      height: 18px;
      position: absolute;
      left: 50%;
      top: -18px;
      margin-top: 0;
      margin-left: -14px;
      content: "";
    }

    .new-block.type-12 .normall {
      margin-bottom: 20px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 20 - BLOCK "TYPE 14" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-14 .tabs-switch {
    float: left;
    width: 100%;
    height: 114px;
    padding-top: 32px;
    border-top: 1px #f2f2f2 solid;
    border-bottom: 1px #f2f2f2 solid;
    border-right: 1px #f2f2f2 solid;
    margin-top: -1px;
    cursor: pointer;
  }

  .new-block.type-14 .tabs-switch img {
    float: left;
  }

  .new-block.type-14 .tabs-switch span {
    display: block;
    margin-left: 65px;
    font-size: 13px;
    line-height: 30px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    padding: 10px 0;
  }

  .new-block.type-14 .tabs-switch.active {
    border-right-color: #fff;
  }

  .new-block.type-14 .tabs-switch.active span {
    color: #144E82;
  }

  .new-block.type-14 .tabs-title {
    font-size: 18px;
    line-height: 30px;
    color: #222;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px !important;
    text-transform: uppercase;
  }

  .new-block.type-14 .tabs-text {
    font-size: 14px;
    line-height: 24px;
    color: #777;
    margin-bottom: 20px !important;
  }

  .new-block.type-14 .tabs-entry img {
    max-width: 260px;
    height: auto;
    margin: 0 auto;
    margin-bottom: 20px !important;
  }

  .new-block.type-14 .tabs-entry .cell-view {
    height: 585px;
    width: 1000px;
  }

  .tabs-entry {
    display: none;
  }

  .tabs-entry:first-child {
    display: block;
  }

  .tabs-limit-container {
    padding-right: 50px;
  }

  @media (max-width: 1199px) {
    .new-block.type-14 .tabs-entry img {
      max-width: 210px;
    }
  }

  @media (max-width: 991px) {
    .new-block.type-14 .tabs-switch {
      margin-bottom: 40px;
    }

    .new-block.type-14 .tabs-entry .cell-view {
      height: auto;
    }

    /**/
    .new-block.type-14 .tabs-switch {
      width: 20%;
      text-align: center;
      border: 1px #f2f2f2 solid;
      margin-left: -1px;
      padding-top: 15px;
    }

    .new-block.type-14 .tabs-switch.active {
      border-bottom: none;
    }

    .new-block.type-14 .tabs-switch img {
      display: inline-block;
      float: none;
      margin-bottom: 10px;
      max-width: 60%;
      height: auto;
    }

    .new-block.type-14 .tabs-switch span {
      display: block;
      margin-left: 0;
      line-height: 18px;
      padding: 0;
    }

    .tabs-limit-container {
      padding-right: 0;
    }
  }

  @media (max-width: 767px) {
    .new-block.type-14 .tabs-switch {
      margin-bottom: 20px;
    }

    .new-block.type-14 .tabs-entry {
      text-align: center;
    }

    .new-block.type-14 .tabs-entry .cell-view {
      margin-bottom: 20px;
    }

    .new-block.type-14 .tabs-switch span {
      font-size: 10px;
      line-height: 12px;
    }

    .new-block.type-14 .tabs-switch {
      height: 100px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 21 - BLOCK "TYPE 15" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .video-thumbnail {
    -webkit-box-shadow: 3px 4px 15px rgba(0, 0, 0, .3);
    box-shadow: 3px 4px 15px rgba(0, 0, 0, .3);
    background: #000;
    max-width: 670px;
    margin: 0 auto;
  }

  .video-thumbnail img {
    opacity: 0.7;
    display: block;
    width: 100%;
    height: auto;
  }

  .new-block.type-15 .cell-view {
    height: 380px;
  }

  .new-block.type-15 .content-entry article {
    padding: 0 0 0 70px;
  }

  .new-block.type-15 .content-entry:first-child article {
    padding: 0 30px 0 0;
  }

  @media (max-width: 991px) {
    .new-block.type-15 .cell-view {
      height: auto;
      display: block;
    }

    .new-block.type-15 .pagination {
      display: block;
    }

    .new-block.type-15 .content-entry article,
    .new-block.type-15 .content-entry:first-child article {
      padding: 0;
    }
  }

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 22 - BLOCK "TYPE 16" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-16 {
    background: #fbfbfb;
  }

  .compare-column-entry {
    margin-bottom: 20px;
  }

  .compare-column-entry .wrapper {
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .05);
    background: #fff;
    text-align: center;
    padding: 70px;
  }

  .compare-column-entry .price {
    font-size: 32px;
    line-height: 40px;
    color: #144E82;
    text-transform: uppercase;
    font-family: 'black';
  }

  .compare-column-entry .time {
    font-size: 16px;
    line-height: 22px;
    color: #777;
    margin-bottom: 32px;
  }

  .compare-column-entry .title {
    font-size: 20px;
    line-height: 25px;
    color: #222;
    text-transform: uppercase;
    font-family: 'black';
    padding: 32px 0;
    border-top: 1px #f2f2f2 solid;
    border-bottom: 1px #f2f2f2 solid;
    margin-bottom: 25px;
  }

  .compare-column-entry .description {
    font-size: 14px;
    color: #777;
    line-height: 34px;
    margin-bottom: 25px;
  }

  .compare-column-entry .description ul li {
    padding: 0;
  }

  .compare-column-entry .description ul li:after {
    display: none;
  }

  @media (max-width: 991px) {
    .compare-column-entry .description {
      padding: 0;
    }

    .compare-column-entry .wrapper {
      padding: 20px;
    }
  }

  @media (max-width: 767px) {}


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 23 - BLOCK "TYPE 17" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .blog-entry {
    margin-bottom: 70px;
  }

  .blog-entry-image {
    width: 100%;
    max-width: 470px;
    display: inline-block;
    vertical-align: bottom;
  }

  .blog-entry-image img {
    display: block;
    width: 100%;
    height: auto;
  }

  .blog-entry .post-data {
    font-size: 12px;
    line-height: 18px;
    color: #144E82;
    text-transform: uppercase;
    font-family: 'black';
    margin-bottom: 15px;
  }

  .blog-entry .post-data .date {
    display: inline-block;
    padding-right: 25px;
  }

  .blog-entry .post-data a {
    display: inline-block;
    color: #144E82;
  }

  .blog-entry .post-data a:hover {
    text-decoration: underline;
  }

  .blog-entry .title {
    font-size: 16px;
    line-height: 28px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
    display: block;
  }

  .blog-entry .title:hover {
    color: #144E82;
  }

  .blog-entry .description {
    font-size: 14px;
    line-height: 24px;
    color: #777;
  }

  .blog-entry .cell-view {
    height: 265px;
    padding: 20px 30px;
  }

  @media (max-width: 991px) {
    .blog-entry {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 767px) {
    .blog-entry .cell-view {
      height: auto;
      padding: 0 20px 20px 0;
    }

    .blog-entry {
      padding: 0px;
      margin-bottom: 50px;
      background: none;
      box-shadow: none;
      -webkit-box-shadow: none;
    }

    .blog-entry-image {
      padding: 0 20px 20px 0;
    }
  }


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 24 - BLOCK "TYPE 18" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-18 {}

  .new-block.type-18 .tabs-switch-wrapper {
    border-bottom: 1px #f2f2f2 solid;
    text-align: center;
    font-size: 0;
    margin-bottom: 70px;
  }

  .new-block.type-18 .tabs-switch {
    display: inline-block;
    vertical-align: bottom;
    font-size: 13px;
    color: #777;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    height: 90px;
    padding: 0 40px;
    border: 1px #f2f2f2 solid;
    margin: 0 0 -1px -1px;
    cursor: pointer;
    background: #fbfbfb;
  }

  .new-block.type-18 .tabs-switch.active {
    border-bottom-color: #fff;
    color: #144E82;
    background: #fff;
  }

  .new-block.type-18 .tabs-switch:hover {
    color: #144E82;
  }

  /*accordeon*/
  .accordeon {}

  .accordeon .entry {
    background: #fbfbfb;
    margin-bottom: 20px;
    position: relative;
    -webkit-box-shadow: inset -5px 0 #ededed;
    box-shadow: inset -5px 0 #ededed;
  }

  .accordeon .entry.active {
    background: #144E82;
    -webkit-box-shadow: inset -5px 0 #daa508;
    box-shadow: inset -5px 0 #daa508;
  }

  .accordeon .entry .title {
    font-size: 12px;
    color: #222;
    line-height: 16px;
    padding: 17px 25px 15px 45px;
    position: relative;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    position: relative;
    cursor: pointer;
  }

  .accordeon .entry .title:hover {
    color: #144E82;
  }

  .accordeon .entry.active .title {
    color: #fff;
  }

  .accordeon .entry.active .title:hover {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .accordeon .entry .title span {
    position: absolute;
    left: 25px;
    top: 17px;
    font-size: 13px;
    color: #144E82;
  }

  .accordeon .entry.active .title span {
    color: rgba(255, 255, 255, 0.8);
    transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    top: 19px;
  }

  .accordeon .entry .text {
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    padding: 12px 25px;
    display: none;
  }

  /*accordeon drop-down*/
  .accordeon.drop-down .entry .title {
    font-size: 14px;
    line-height: 18px;
    color: #777;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    padding: 12px 35px 12px 20px;
  }

  .accordeon.drop-down .entry .title .glyphicon {
    font-size: 12px;
    line-height: 14px;
    left: auto;
    right: 20px;
    top: 14px;
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }

  .drop-down-list li {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 18px;
    padding: 12px 0;
  }

  .drop-down-list a {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
  }

  .drop-down-list a:hover {
    text-decoration: underline;
  }

  .accordeon.drop-down .entry .text {
    padding: 0 20px;
  }

  .accordeon.drop-down .entry.active .title {
    color: #fff;
  }

  .accordeon.drop-down .entry.active .title:hover {
    color: #fff0e0;
  }

  .accordeon.drop-down .entry.active .title .glyphicon {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }

  /**/
  @media (max-width: 991px) {
    .new-block.type-18 .tabs-switch {
      height: 70px;
      padding: 0 30px;
    }

    .new-block.type-18 .tabs-switch-wrapper {
      margin-bottom: 50px;
    }
  }

  @media (max-width: 767px) {
    .new-block.type-18 .tabs-switch {
      width: 50%;
      float: left;
    }

    .new-block.type-18 .tabs-switch .cell-view {
      width: 500px;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 25 - BLOCK "TYPE 19" (index style 2) */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .new-block.type-19 {
    padding-bottom: 0;
  }

  .sorting-item,
  .grid-sizer {
    width: 20%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  .full-page {
    margin-bottom: 120px;
  }

  .full-page .sorting-item,
  .full-page .grid-sizer {
    width: 25%;
  }

  .full-page .sorting-item.w2 {
    width: 50%;
  }

  .sorting-item img {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
  }

  .sorting-menu {
    text-align: center;
    font-size: 0;
    margin-bottom: 70px;
  }

  .sorting-menu a {
    display: inline-block;
    font-size: 13px;
    line-height: 30px;
    color: #888;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-right: 15px;
    padding: 2px 10px 0 10px;
    margin-bottom: 5px;
  }

  .sorting-menu a.active,
  .sorting-menu a:hover {
    color: #fff;
    background: #144E82;
  }

  .sorting-menu a:first-child {
    margin-left: 0;
  }

  .sorting-item.disabled {
    opacity: 0.1;
  }

  .full-page .sorting-item.disabled {
    opacity: 0;
  }

  .sorting-item .tagline {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(251, 192, 17, 0.8);
    opacity: 0;
  }

  .sorting-item:hover .tagline {
    opacity: 1;
  }

  .sorting-item .tagline .content {
    position: absolute;
    width: 90%;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    text-align: center;
  }

  .sorting-item .tagline .title {
    font-size: 22px;
    line-height: 40px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    transform: translateX(-100%);
    -oz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
  }

  .sorting-item:nth-child(2n) .tagline .title {
    transform: translateX(0%) translateY(-100%);
    -oz-transform: translateX(0%) translateY(-100%);
    -webkit-transform: translateX(0%) translateY(-100%);
    -ms-transform: translateX(0%) translateY(-100%);
  }

  .sorting-item .tagline .description {
    font-size: 11px;
    line-height: 24px;
    text-transform: uppercase;
    transform: translateX(100%);
    -oz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
  }

  .sorting-item:nth-child(2n) .tagline .description {
    transform: translateX(0%) translateY(100%);
    -oz-transform: translateX(0%) translateY(100%);
    -webkit-transform: translateX(0%) translateY(100%);
    -ms-transform: translateX(0%) translateY(100%);
  }

  .sorting-item:hover .tagline .title,
  .sorting-item:hover .tagline .description {
    transform: translateX(0%) translateY(0%);
    -oz-transform: translateX(0%) translateY(0%);
    -webkit-transform: translateX(0%) translateY(0%);
    -ms-transform: translateX(0%) translateY(0%);
  }

  .full-page.borders .sorting-item:after {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 15px #fff solid;
    left: 0;
    top: 0;
    content: "";
  }

  /*dropdown for responsive*/
  .responsive-filtration-title {
    display: none;
    position: relative;
  }

  @media (max-width: 991px) {
    .sorting-menu {
      margin-bottom: 50px;
    }

    .full-page.borders .sorting-item:after {
      border-width: 5px;
    }

    /*dropdown for responsive*/
    .responsive-filtration-title {
      display: block;
      color: #222;
      border-bottom: 5px #144E82 solid;
      font-size: 22px;
      font-family: 'Poppins', sans-serif;
      line-height: 40px;
      padding: 0 40px;
      cursor: pointer;
    }

    .responsive-filtration-title .glyphicon {
      position: absolute;
      line-height: 40px;
      width: 40px;
      text-align: center;
      top: 0;
      right: 0;
      font-size: 18px;
      color: #144E82;
    }

    .sorting-menu.active .responsive-filtration-title .glyphicon {
      transform: rotate(-180deg);
      -moz-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
    }

    .responsive-filtration-toggle {
      display: none;
    }

    .sorting-menu.active .responsive-filtration-toggle {
      display: block;
    }

    .sorting-menu a {
      display: block;
      margin: 10px 0 0 0;
    }
  }

  @media (max-width: 767px) {

    .sorting-item,
    .grid-sizer {
      width: 50%;
    }

    .full-page .sorting-item,
    .full-page .grid-sizer,
    .full-page .sorting-item.w2 {
      width: 50%;
    }
  }

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 26 - BLOG */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .blog-container {
    padding-top: 75px;
  }

  .blog-post {
    margin-bottom: 90px;
    clear: both;
  }

  .blog-post .thumbnail-entry {
    display: block;
    margin-bottom: 40px;
  }

  .blog-post .thumbnail-entry img {
    display: block;
    width: 100%;
    height: auto;
  }

  .blog-post .thumbnail-entry blockquote {
    /* background: url(../img/theme-1/blockquote-bg-1.png) 40px 30px no-repeat #f8f8f8; */
    padding: 70px 40px 20px 40px;
    font-size: 20px;
    line-height: 24px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    border-left: 5px #144E82 solid;
  }

  .blog-post .thumbnail-entry blockquote.style-2 {
    background: none;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    border: none;
  }

  .blog-post .thumbnail-entry blockquote.style-2:before {
    width: 2px;
    top: 18px;
    bottom: 18px;
    position: absolute;
    left: 0;
    background: #144E82;
    content: "";
  }

  .blog-post .thumbnail-entry blockquote footer {
    background: none;
    font-size: 16px;
    line-height: 30px;
    color: #777;
    font-family: 'Poppins', sans-serif;
    margin-top: 5px;
  }

  .blog-post .thumbnail-entry blockquote cite {
    color: #144E82;
    font-family: 'Poppins';
  }

  .blog-post .thumbnail-entry blockquote.style-2 cite {
    color: #222;
  }

  .blog-post .thumbnail-entry .default-arrow.left {
    left: 20px;
  }

  .blog-post .thumbnail-entry .default-arrow.right {
    right: 20px;
  }

  .blog-post .thumbnail-entry .pagination {
    position: absolute !important;
    left: 0;
    width: 100% !important;
    bottom: 10px;
    top: auto;
    display: none;
  }

  .blog-post .data {}

  .blog-post .date {
    float: left;
    width: 70px;
    border-right: 2px #144E82 solid;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    color: #222;
    padding-top: 3px;
  }

  .blog-post .date span {
    display: block;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
  }

  .blog-post .text {
    margin-left: 70px;
    padding: 0 0 0 30px;
  }

  .blog-post .title {
    font-size: 30px;
    line-height: 34px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px !important;
    display: inline-block;
  }

  .blog-post.detail-post .title {
    margin-bottom: 35px !important;
  }

  .blog-post .title:hover {
    color: #144E82;
  }

  .blog-post .description {
    font-size: 14px;
    line-height: 24px;
    color: #777;
    margin-bottom: 30px;
  }

  /*paginator*/
  .paginator {}

  .paginator ul {
    list-style: none;
    margin: 0 100px -40px 100px;
    font-size: 0;
    text-align: center;
  }

  .paginator ul li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
  }

  .paginator ul li:after {
    display: none;
  }

  .paginator ul li a {
    font-size: 12px;
    line-height: 35px;
    color: #888;
    display: block;
    width: 35px;
    font-family: 'Poppins', sans-serif;
  }

  .paginator ul li a.active,
  .paginator ul li a:hover {
    color: #fff;
    background: #144E82;
  }

  .arrow-button:hover {
    color: #144E82;
  }

  .arrow-button {
    float: right;
    font-size: 12px;
    line-height: 42px;
    color: #888;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
  }

  .arrow-button:first-child {
    float: left;
  }

  /*widgets*/
  .widget-entry {
    margin-bottom: 70px;
  }

  .widget-title {
    font-size: 20px;
    line-height: 20px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 40px !important;
  }

  .widget-entry .swiper-container img {
    width: 100%;
    height: auto;
  }

  .widget-entry .default-arrow {
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    margin-top: -44px;
  }

  .widget-entry .default-arrow.left {
    left: 15px;
  }

  .widget-entry .default-arrow.right {
    right: 15px;
  }

  .categories-wrapper {}

  .categories-wrapper .entry {
    font-size: 12px;
    line-height: 14px;
    padding: 14px 30px 14px 0;
    display: block;
    position: relative;
    color: #888;
    text-transform: uppercase;
    border-bottom: 1px #f2f2f2 solid;
    cursor: pointer;
  }

  .categories-wrapper .entry .number {
    position: absolute;
    right: 0;
    top: 14px;
    color: #144E82;
  }

  .categories-wrapper .entry .glyphicon {
    font-size: 10px;
    margin-right: 10px;
    position: relative;
  }

  .categories-wrapper .entry.toggle .glyphicon:before {
    content: "+";
    color: #144E82;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
  }

  .categories-wrapper .entry.toggle.active .glyphicon:before {
    content: "-";
  }

  .categories-wrapper .entry:hover {
    color: #144E82;
  }

  .categories-wrapper .sub-wrapper {
    border-bottom: 1px #f2f2f2 solid;
    display: none;
  }

  .categories-wrapper .sub-wrapper:last-child {
    border: none;
  }

  .categories-wrapper .sub-wrapper a {
    display: block;
    font-size: 12px;
    line-height: 14px;
    color: #888;
    padding: 14px 0 14px 40px;
  }

  .categories-wrapper .sub-wrapper a span {
    color: #144E82;
  }

  /**/
  .popular-post-entry {
    padding-bottom: 35px;
    border-bottom: 1px #f2f2f2 solid;
    margin-bottom: 35px;
  }

  .popular-post-entry .popular-thumbnail {
    float: left;
  }

  .popular-post-entry .popular-thumbnail img {
    display: block;
    width: 80px;
    height: 80px;
  }

  .popular-post-entry .content {
    margin-left: 100px;
  }

  .popular-post-entry .content .date {
    font-size: 12px;
    line-height: 14px;
    color: #144E82;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
  }

  .popular-post-entry .content .title {
    font-size: 13px;
    line-height: 20px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    display: block;
  }

  .popular-post-entry .content .title:hover {
    color: #144E82;
  }

  /**/
  .tags-container {
    font-size: 0;
  }

  .tags-container a {
    font-size: 11px;
    line-height: 32px;
    color: #555;
    display: inline-block;
    margin-right: 10px;
    background: #f8f8f8;
    padding: 0 10px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .tags-container a:hover {
    color: #fff;
    background: #144E82;
  }

  .tags-title {
    display: inline-block;
    line-height: 32px;
    font-size: 14px;
    color: #222;
    text-transform: uppercase;
    margin-right: 20px;
    font-family: 'Poppins', sans-serif;
  }

  /**/
  .text-widget-image {
    max-width: 370px;
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
  }

  .text-widget-description {
    font-size: 14px;
    line-height: 24px;
    color: #777;
    margin-bottom: 20px;
  }

  /**/
  .latest-comment-entry {
    margin-bottom: 30px;
  }

  .latest-comment-entry .date {
    font-size: 11px;
    line-height: 18px;
    color: #888;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
  }

  .latest-comment-entry .date .glyphicon {
    color: #144E82;
    font-size: 16px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 3px;
  }

  .latest-comment-entry .title {
    font-size: 13px;
    line-height: 20px;
    color: #888;
  }

  .latest-comment-entry .title .name {
    color: #144E82;
    text-transform: uppercase;
  }

  .latest-comment-entry .title .name:hover {
    text-decoration: underline;
  }

  .latest-comment-entry .title .post-title {
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
  }

  .latest-comment-entry .title .post-title:hover {
    color: #144E82;
  }

  /**/
  .gallery-preview img {
    float: left;
    width: 25%;
    height: auto;
  }

  /**/
  .widget-entry .items-1 .tabs-switch {
    width: 100%;
  }

  .widget-entry .items-2 .tabs-switch {
    width: 50%;
  }

  .widget-entry .items-3 .tabs-switch {
    width: 33.333333333%;
  }

  .widget-entry .items-4 .tabs-switch {
    width: 25%;
  }

  .widget-entry .tabs-switch {
    padding: 0 5px;
    border: 1px #f2f2f2 solid;
    float: left;
    height: 70px;
    font-size: 13px;
    color: #777;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    text-align: center;
    background: #fbfbfb;
    cursor: pointer;
  }

  .widget-entry .tabs-switch.active {
    color: #144E82;
    background: #fff;
    border-bottom-color: #fff;
  }

  .widget-entry .tabs-switch .cell-view {
    width: 1000px;
  }

  .widget-tab-wrapper {
    padding: 20px 30px;
    border: 1px #f2f2f2 solid;
    border-top: none;
  }

  .tab-style .widget-tab-wrapper {
    border: none;
  }

  .widget-entry .tabs-entry img {
    display: inline-block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }

  .widget-entry .tabs-entry .cell-view {
    height: 250px;
  }

  /**/
  .widget-testimonial {
    padding-right: 10px;
  }

  .widget-testimonial .text {
    font-size: 14px;
    line-height: 20px;
    color: #777;
    padding: 15px 25px 15px 50px;
    border: 1px #f2f2f2 solid;
    border-radius: 5px;
    /* background: url(../img/theme-1/blockquote-bg.png) 20px 17px no-repeat #fff; */
    margin-bottom: 35px;
    position: relative;
  }

  .widget-testimonial .text:after {
    width: 28px;
    height: 18px;
    /* background: url(../img/widget-testimonial-triangle.png); */
    position: absolute;
    content: "";
    left: 55px;
    top: 100%;
  }

  .widget-testimonial .person {}

  .widget-testimonial .person img {
    width: 115px;
    height: auto;
    float: left;
    margin-left: 12px;
  }

  .widget-testimonial .person .description {
    margin-left: 157px;
    font-size: 13px;
    line-height: 24px;
    color: #777;
    padding-top: 30px;
  }

  .widget-testimonial .person .description span {
    display: block;
    font-size: 16px;
    color: #222;
    font-family: 'Poppins', sans-serif;
  }

  .testimonial-widget-pagination {
    width: 139px !important;
  }

  /**/
  .search-form {
    position: relative;
  }

  .search-form input {
    height: 43px;
    padding: 0 43px 0 20px;
    width: 100%;
    background: #f8f8f8;
    font-size: 14px;
    line-height: 43px;
    font-family: 'Poppins', sans-serif;
    border: none;
    margin: 0;
  }

  .search-form .search-submit {
    position: absolute;
    height: 43px;
    width: 43px;
    text-align: center;
    color: #144E82;
    line-height: 43px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    cursor: pointer;
    top: 0;
    right: 0;
  }

  .search-form .search-submit span {
    position: relative;
  }

  .search-form .search-submit input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 0;
  }

  /**/
  .widget-entry .social-icons {
    font-size: 0;
  }

  .widget-entry .social-icons a {
    width: 35px;
    height: 35px;
    display: inline-block;
    background: #144E82;
    text-align: center;
    line-height: 35px;
    margin-right: 15px;
  }

  .widget-entry .social-icons a:hover {
    background: #777;
  }

  .widget-entry .social-icons a img {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 1px;
  }

  /**/
  .tweet-entry {
    position: relative;
    padding-left: 35px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 24px;
    color: #777;
  }

  .tweet-entry a {
    color: #144E82;
    font-family: 'Poppins', sans-serif;
  }

  .tweet-entry a:hover {
    text-decoration: underline;
  }

  .tw-logo {
    position: absolute;
    left: 0;
    top: 5px;
  }

  .tweet-entry .date {
    display: block;
    color: #cbcaca;
    font-family: 'Poppins', sans-serif;
  }



  /*blog post style 2*/
  @media (min-width: 992px) {
    .blog-post.style-2 {
      padding-left: 100px;
    }

    .blog-post.style-2 .date {
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      background-color: #144E82;
      -webkit-box-shadow: inset 3px 0 #daa508;
      box-shadow: inset 3px 0 #daa508;
      border: none;
      color: #fff;
      text-shadow: 1px 0 1px rgba(0, 0, 0, .1);
      padding: 20px 0;
      z-index: 1;
    }

    .blog-post.style-2 .date:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 0 7.5px 10px;
      border-color: transparent transparent transparent #144E82;
      content: "";
      position: absolute;
      left: 100%;
      top: 0;
    }

    .blog-post.style-2 .date br {
      display: none;
    }

    .blog-post.style-2 .text {
      margin-left: 0;
      padding-left: 0;
    }
  }

  /*author*/
  .author-entry {
    font-size: 11px;
    color: #888;
    line-height: 15px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }

  .author-thumbnail {
    float: left;
  }

  .author-thumbnail img {
    width: 39px;
    height: 39px;
    display: block;
  }

  .author-text {
    margin-left: 50px;
    padding: 12px 0;
  }

  .author-text .name {
    text-transform: uppercase;
    color: #888;
  }

  .author-text .category {
    text-transform: uppercase;
    color: #144E82;
  }

  .author-text a:hover {
    text-decoration: underline;
  }

  .author-entry .glyphicon {
    color: #144E82;
    font-size: 14px;
    margin-right: 7px;
    margin-left: 25px;
  }

  /*slider thumbnails*/
  .blog-post .thumbnails {
    cursor: pointer;
  }

  .blog-post .thumbnails .entry {
    opacity: 0.3;
    margin-top: 30px;
  }

  .blog-post .thumbnails .entry.active {
    opacity: 1;
  }

  .blog-post .thumbnails img {
    width: 100%;
    height: auto;
  }

  /*blog post style 3*/
  .blog-post.style-3 {
    position: relative;
  }

  .blog-post.style-3:after {
    position: absolute;
    width: 3000px;
    left: 50%;
    margin-left: -1500px;
    bottom: -30px;
    height: 1px;
    background: #f2f2f2;
    content: "";
  }

  @media (min-width: 992px) {
    .blog-post.style-3 .title {
      font-size: 18px;
      line-height: 28px;
    }

    .blog-post.style-3 .author-thumbnail img {
      width: 60px;
      height: 60px;
    }

    .blog-post.style-3 .author-text {
      margin-left: 70px;
      padding: 22px 0;
    }
  }

  /*blog detail page*/
  .blog-post.detail-post {
    padding-bottom: 90px;
    border-bottom: 1px #f2f2f2 solid;
    margin-bottom: 90px;
  }

  .blog-post.detail-post .text {
    margin-left: 0;
    padding: 0;
  }

  .blog-post.detail-post .author-entry {
    margin-bottom: 35px;
  }

  .blog-post-hat-center-align {
    text-align: center;
    margin-bottom: 20px;
  }

  .blog-article-description {
    font-size: 18px;
    line-height: 30px;
    color: #777;
    margin-bottom: 20px;
  }

  .author-center-align {
    text-align: center;
  }

  .author-center-align .author-entry {
    display: inline-block;
  }

  .blog-detail-article {
    font-size: 14px;
    line-height: 24px;
    color: #777;
  }

  .new-block .blog-detail-article p,
  .new-block .blog-detail-article ul,
  .new-block .blog-detail-article ol,
  .new-block .blog-detail-article img {
    margin-bottom: 20px !important;
  }

  .blog-detail-article img {
    max-width: 100%;
    height: auto;
  }

  .blog-detail-article h3 {
    font-size: 18px;
    line-height: 30px;
    color: #222;
    text-transform: uppercase;
    margin-bottom: 20px !important;
    font-family: 'Poppins', sans-serif;
  }

  .blog-post.detail-post .thumbnail-entry {
    margin-bottom: 20px !important;
  }

  .share-post {
    text-align: right;
  }

  .share-post a {
    display: inline-block;
    width: 32px;
    text-align: center;
    height: 32px;
    position: relative;
    top: -1px;
    vertical-align: middle;
  }

  .share-post a:hover {
    background: #144E82;
  }

  .share-post a img {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    margin: -8px 0 0 -8px;
    opacity: 0;
  }

  .share-post a img:first-child {
    opacity: 1;
  }

  .share-post a:hover img {
    opacity: 1;
  }

  .share-post a:hover img:first-child {
    opacity: 0;
  }

  .related-posts {
    padding-bottom: 90px;
    border-bottom: 1px #f2f2f2 solid;
    margin-bottom: 90px;
  }

  .related-posts .titel-left {
    margin-left: 25px !important;
    margin-bottom: 60px !important;
  }

  .related-entry {
    margin-bottom: 20px;
    padding-top: 10px;
  }

  .related-entry.content {
    padding: 0 30px 0 0;
  }

  .related-entry img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .related-entry .title {
    font-size: 18px;
    line-height: 24px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    display: block;
    margin-bottom: 20px;
  }

  .related-entry .description {
    font-size: 14px;
    line-height: 24px;
    color: #777;
  }

  .related-entry .blog-entry {
    background: none;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .related-entry .author-entry {
    margin-bottom: 20px;
  }

  .back-button {
    margin-bottom: 35px;
    text-align: center;
  }

  .back-button .glyphicon {
    position: relative;
    top: 2px !important;
    margin-right: 7px;
    float: none !important;
    width: auto !important;
  }

  .new-block .article-thumbnails {
    margin-bottom: 20px !important;
  }

  .new-block .article-thumbnails img {
    float: left;
    width: 33.33%;
    height: auto;
    margin-bottom: 0 !important;
  }

  /*comments*/
  .blog-comments {
    margin-bottom: 70px;
  }

  .blog-comments .titel-left {
    margin-bottom: 60px !important;
    margin-left: 25px !important;
  }

  .blog-comments-container {
    margin-left: -160px;
  }

  .comment-entry {
    padding-top: 40px;
    margin-left: 120px;
    border-left: 1px #f2f2f2 solid;
    position: relative;
  }

  .comment-entry:before {
    content: "";
    position: absolute;
    width: 1px;
    background: #f2f2f2;
    height: 40px;
    left: 120px;
    top: 0;
  }

  .comment-entry .comment-entry:before {
    width: 40px;
    height: 1px;
    left: 0;
    top: 122px;
  }

  .comment-entry:first-child {
    padding-top: 0;
  }

  .blog-comments-container>.comment-entry {
    border: none;
  }

  .comment-entry:first-child:before {
    display: none;
  }

  .comment-image {
    width: 165px;
    float: left;
    margin-left: 40px;
  }

  .comment-image img {
    width: 100%;
    height: auto;
    display: block;
  }

  .comment-content {
    margin-left: 235px;
    padding-top: 20px;
  }

  .comment-content .name {
    font-size: 16px;
    line-height: 20px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 10px;
    padding-right: 75px;
    position: relative;
  }

  .comment-content .name .reply {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 11px;
    color: #144E82;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }

  .comment-content .name .reply span {
    font-size: 14px;
    position: relative;
    top: 2px;
    margin-right: 5px;
  }

  .comment-content .name .reply:hover {
    color: #777;
  }

  .comment-content .date {
    font-size: 11px;
    line-height: 14px;
    color: #cbcaca;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
  }

  .comment-content .date .glyphicon {
    color: #144E82;
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .comment-content .description {
    font-size: 14px;
    line-height: 24px;
    color: #777;
  }

  .comment-form {
    margin: 0 -2%;
  }

  .blog-content-column .comment-form {
    margin-top: -14px;
  }

  /*posts navigation*/
  .posts-navigation {
    border-top: 1px solid #f2f2f2;
    margin-top: -1px;
  }

  .posts-navigation .thumbnail-image {
    width: 278px;
    float: left;
  }

  .posts-navigation .align-left {
    border-right: 1px solid #f2f2f2;
  }

  .posts-navigation .align-right .thumbnail-image {
    float: right;
  }

  .posts-navigation .description {
    margin-left: 315px;
    margin-right: 15px;
  }

  .posts-navigation .align-right .description {
    margin-left: 15px;
    margin-right: 315px;
  }

  .posts-navigation .thumbnail-image img {
    width: 100%;
    height: auto;
    display: block;
  }

  .posts-navigation .cell-view {
    height: 278px;
    width: 2000px;
  }

  .posts-navigation .align-right {
    text-align: right;
  }

  .posts-navigation .width-wrapper {
    float: left;
    width: 100%;
  }

  .posts-navigation .align-right .width-wrapper {
    float: right;
  }

  .posts-navigation .title {
    font-size: 24px;
    color: #222;
    line-height: 28px;
    display: block;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }

  .posts-navigation .title:hover {
    color: #144E82;
  }

  .posts-navigation .data {
    font-size: 11px;
    line-height: 14px;
    color: #777;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
  }

  .posts-navigation .data .block {
    display: inline-block;
    margin-right: 5px;
    width: auto;
  }

  .posts-navigation .data .glyphicon {
    font-size: 14px;
    color: #144E82;
    margin-right: 5px;
  }

  .posts-navigation .data .category {
    color: #144E82;
  }

  .posts-navigation .text {
    font-size: 13px;
    line-height: 24px;
    color: #777;
  }

  .small-button {
    font-size: 13px;
    line-height: 30px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-shadow: 1px 0 1px rgba(0, 0, 0, .1);
    text-transform: uppercase;
    padding: 3px 10px 0 10px;
    display: inline-block;
    background: #144E82;
  }

  .small-button:hover {
    color: #222;
    background: #f8f8f8;
  }

  .posts-navigation .small-button {
    margin-bottom: 25px;
  }


  @media (max-width: 991px) {
    .blog-content-column:first-child {
      margin-bottom: 50px;
    }

    .blog-post .thumbnail-entry .pagination {
      display: block;
    }

    .blog-post {
      margin-bottom: 50px;
    }

    .widget-entry {
      margin-bottom: 50px;
    }

    .gallery-preview img {
      width: 16.6%;
    }
  }

  @media (max-width: 767px) {
    .blog-comments {
      margin-bottom: 30px;
    }

    .blog-post.detail-post,
    .related-posts {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }

    .share-post {
      text-align: left;
    }

    .blog-comments-container {
      margin-left: -30px;
    }

    .comment-entry {
      margin-left: 30px;
      border: none;
    }

    .comment-entry:before {
      display: none;
    }

    .comment-image {
      width: 100px;
      margin-left: 0;
    }

    .comment-content {
      margin-left: 110px;
      padding-top: 0;
    }

    .author-text .block {
      display: block;
    }

    .author-entry .glyphicon {
      margin-left: 0;
    }

    .posts-navigation {
      padding: 20px 0;
    }

    .posts-navigation .thumbnail-image {
      display: none;
    }

    .posts-navigation .description,
    .posts-navigation .align-right .description {
      margin: 0 15px 20px 15px;
      text-align: left;
    }

    .posts-navigation .width-wrapper,
    .posts-navigation .align-right .width-wrapper {
      max-width: 100%;
      float: none;
    }

    .posts-navigation .cell-view {
      height: auto;
    }
  }

  @media (max-width: 480px) {
    .gallery-preview img {
      width: 25%;
    }

    .blog-post .date {
      float: none;
      width: auto;
      text-align: left;
      margin-bottom: 10px;
    }

    .blog-post .date br {
      display: none;
    }

    .blog-post .date span {
      display: inline-block;
    }

    .blog-post .text {
      margin-left: 0;
      padding: 0;
    }

    .paginator ul {
      margin: 0;
    }

    .blog-post .thumbnails,
    .blog-post .thumbnails * {
      padding: 0;
    }

    .blog-post .thumbnails .entry {
      margin-top: 0;
    }

    .blog-comments-container {
      margin-left: 0px;
    }

    .comment-entry {
      margin-left: 0px;
    }
  }


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 27 - POPUP "VIDEO" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .video-popup {
    position: fixed;
    left: -100%;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 500;
  }

  .video-popup span {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 0px;
    background: #000;
  }

  .video-popup span:nth-child(1) {
    top: -50%;
    left: -50%;
  }

  .video-popup span:nth-child(2) {
    bottom: -50%;
    left: -50%;
  }

  .video-popup span:nth-child(1):after {
    position: absolute;
    left: 300%;
    top: -100%;
    background: #000;
    width: 100%;
    height: 100%;
    content: "";
  }

  .video-popup span:nth-child(2):after {
    position: absolute;
    left: 300%;
    top: 0px;
    background: #000;
    width: 100%;
    height: 100%;
    content: "";
  }

  .video-popup.act-act {
    left: 0px;
  }

  .video-popup.act span:nth-child(1) {
    top: 0px;
    left: 0px;
  }

  .video-popup.act span:nth-child(1):after {
    left: 100%;
    top: 0px;
  }

  .video-popup.act span:nth-child(2) {
    bottom: 0px;
    left: 0px;
  }

  .video-popup.act span:nth-child(2):after {
    left: 100%;
    top: 0px;
  }

  .video-popup iframe {
    position: absolute;
    left: 0px;
    bottom: 0%;
    opacity: 0;
    width: 100%;
    height: 90%;
  }

  .video-popup iframe.act {
    opacity: 1;
  }

  .video-popup a {
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    width: 100%;
    height: 10%;
    text-align: center;
    cursor: pointer;
  }

  .video-popup a.act {
    opacity: 1;
  }

  .video-popup a:after {
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -30px;
    height: 60px;
    content: "";
    color: #144E82;
    font-size: 60px;
    width: 100%;
    text-align: center;
    /* background: url(../img/theme-1/cross.png) no-repeat scroll 50% 50%; */
  }

  .video-popup a:hover {
    background: #144E82;
    color: #fff;
  }

  .video-popup a:hover:after {
    color: #fff;
    /* background: url(../img/cross-act.png) no-repeat scroll 50% 50%; */
  }

  @media (max-width: 991px) {
    .video-popup {
      opacity: 0;
    }

    .video-popup.act-act {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {}

  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 28 - POPUP "GALLERY" */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .overflow-hidden {
    overflow: hidden;
  }

  .gallery-popup,
  .screen-preview-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    background: rgba(0, 0, 0, 0.8);
  }

  /*.gallery-popup .close-layer{position: absolute; left: 0; top: 0; width: 100%; height: 100%;}*/
  .gallery-popup.active,
  .screen-preview-popup.active {
    transform: translateX(0%);
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
  }

  .gallery-popup .overflow,
  .screen-preview-popup .overflow {
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }

  .gallery-popup .swiper-container {
    padding-bottom: 100px;
    padding-top: 50px;
  }

  .gallery-popup .image-container {
    width: 80%;
    max-width: 770px;
    margin: 0 auto;
  }

  .gallery-popup .image-container img {
    width: 100%;
    height: auto;
    display: block;
  }

  .gallery-popup .image-container .description {
    background: #fff;
    padding: 40px;
  }

  .gallery-popup .image-container .title {
    font-size: 18px;
    line-height: 28px;
    color: #222;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 22px;
  }

  .gallery-popup .image-container .text {
    font-size: 14px;
    line-height: 24px;
    color: #777;
  }

  .gallery-popup .close-popup,
  .screen-preview-popup .close-popup {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    color: #144E82;
    font-size: 30px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    height: 100px;
    text-align: center;
    line-height: 100px;
    background: #000;
  }

  .gallery-popup .close-popup:hover,
  .screen-preview-popup .close-popup:hover {
    background: #144E82;
    color: #fff;
  }

  .gallery-popup .close-popup:active span {
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
  }

  .gallery-popup .default-arrow {
    margin-top: 24px;
    z-index: 1;
  }

  .screen-preview-popup {
    text-align: center;
  }

  .screen-preview-popup img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  @media (max-width: 991px) {
    .gallery-popup .default-arrow.left {
      display: block;
      left: 3%;
    }

    .gallery-popup .default-arrow.right {
      display: block;
      right: 3%;
    }
  }

  @media (max-width: 767px) {
    .gallery-popup .image-container .description {
      padding: 30px 20px;
    }
  }


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 29 - TYPOGRAPHY */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .typography-article {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 28px;
  }

  .typography-article.small-font {
    font-size: 14px;
    line-height: 24px;
  }

  .typography-article * {
    color: #777;
  }

  .color-background .typography-article * {
    color: #fff;
  }

  .typography-article h1 {
    font-size: 60px;
    line-height: 60px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px !important;
    color: #222;
  }

  .typography-article h2 {
    font-size: 46px;
    line-height: 46px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px !important;
    color: #222;
  }

  .typography-article h3 {
    font-size: 30px;
    line-height: 30px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 20px !important;
    color: #222;
  }

  .typography-article h4 {
    font-size: 20px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 11px !important;
    color: #222;
  }

  .typography-article h5 {
    font-size: 18px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 11px !important;
    color: #222;
  }

  .typography-article h6 {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 11px !important;
    color: #222;
  }

  .typography-article p,
  .typography-article ul,
  .typography-article ol,
  .typography-article .table-responsive {
    color: #777;
    margin-bottom: 24px;
  }

  .typography-article ul li,
  .typography-article ol li {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
    line-height: 24px;
  }

  .typography-article ol {
    counter-reset: number;
    list-style: outside none none;
  }

  .typography-article ol li::before {
    color: #fff;
    content: counter(number, decimal);
    counter-increment: number;
    font-family: "bold";
    margin-right: 15px;
    width: 17px;
    height: 17px;
    display: inline-block;
    text-align: center;
    font-size: 10px;
    line-height: 17px;
    background: #144E82;
    position: relative;
    top: -3px;
  }

  .typography-article ul.list-style-3 li {
    padding-left: 20px;
  }

  .typography-article ul.list-style-3 li:before {
    width: 7px;
    height: 2px;
    position: absolute;
    left: 0;
    content: "";
    top: 14px;
    background: #144E82;
  }

  .typography-article ul.list-style-3 li:after {
    display: none;
  }

  /**/
  .message-box-entry {
    font-size: 14px;
    line-height: 24px;
    position: relative;
    margin-bottom: 30px;
  }

  .message-box-entry.style-1 {
    background: #144E82;
    color: rgba(255, 255, 255, 0.8);
    box-shadow: 3px 4px 10px rgba(0, 0, 0, .1);
    padding: 30px;
  }

  .message-box-entry.style-1:before {
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: #daa508;
  }

  .message-box-entry.style-2 {
    background: #222;
    color: #c9c9c9;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, .1);
    padding: 30px;
  }

  .message-box-entry.style-2:before {
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background: #000;
  }

  .message-box-entry.style-3 {
    background: #fff;
    border: 5px #144E82 solid;
    color: #144E82;
    padding: 25px;
  }

  .message-box-entry .close-box {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }

  /**/
  .typography-article .table {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    text-align: center;
  }

  .typography-article .table th {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    padding: 15px 25px;
    background: #144E82;
    text-align: center;
  }

  .typography-article .table td {
    padding: 15px 25px;
    border-bottom: 1px #f2f2f2 solid;
    background: #fbfbfb;
  }

  .typography-article .table td:first-child {
    font-family: 'Poppins', sans-serif;
  }

  .typography-article .table tr:nth-child(2n) td {
    background: #fff;
  }

  .typography-article .table td:first-child,
  .typography-article .table th:first-child {
    text-align: left;
  }

  /**/
  .default-arrow.simple-arrow {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    margin-right: 20px;
    display: inline-block;
  }

  .default-arrow.black {
    background: #222;
    box-shadow: none;
  }

  .default-arrow.black:hover {
    color: #000;
  }

  .default-arrow.black span {
    color: #144E82;
  }

  .default-arrow.black:hover span {
    color: #fff;
  }

  /**/
  .checkbox-entry {
    margin: 0 0 10px 0 !important;
    float: left;
    clear: both;
  }

  .checkbox-entry input {
    position: absolute;
    left: -30px;
    display: none;
  }

  .checkbox-entry label {
    font-size: 14px;
    color: #777;
    line-height: 20px;
    display: block;
    padding-left: 30px;
    position: relative;
    cursor: pointer;
    position: relative;
  }

  .checkbox-entry label:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px #edecec solid;
    background: #fbfbfb;
  }

  .checkbox-entry.active label:before {
    background-color: #fff;
    border: 2px #144E82 solid;
  }

  .checkbox-entry.active label:after {
    content: "";
    width: 5px;
    height: 10px;
    border: 2px #144E82 solid;
    border-bottom: none;
    border-right: none;
    position: absolute;
    left: 8px;
    top: 3px;
    transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
  }

  .checkbox-entry.radio label:before {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #fff;
  }

  .checkbox-entry.active.radio label:after {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background: #144E82;
    left: 6px;
    top: 50%;
    margin-top: -4px;
    content: "";
  }


  /*-------------------------------------------------------------------------------------------------------------------------------*/
  /* 30 - THEME CONFIG */
  /*-------------------------------------------------------------------------------------------------------------------------------*/
  .theme-config {
    position: fixed;
    left: -152px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    width: 152px;
    min-height: 50px;
    background: #fff;
    border: 1px #e7e7e7 solid;
    z-index: 10;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    padding: 10px;
  }

  .theme-config.active {
    left: 0;
  }

  .theme-config .open {
    position: absolute;
    width: 60px;
    height: 60px;
    background: #fff;
    border: 1px #e7e7e7 solid;
    border-left: none;
    left: 100%;
    top: 50%;
    margin-top: -30px;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
  }

  .theme-config .open img {
    -webkit-backface-visibility: hidden;
  }

  .theme-config .open img:nth-child(1) {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 32px;
    height: auto;
  }

  .theme-config .open img:nth-child(2) {
    position: absolute;
    left: 33px;
    top: 23px;
    width: 22px;
    height: auto;
  }

  .theme-config .open img:nth-child(3) {
    position: absolute;
    left: 23px;
    top: 40px;
    width: 15px;
    height: auto;
  }

  .flex--form {
    margin-top: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    /* background-image: url("../../../img/anglesImages/002.png"); */
    border-radius: 30px;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
    padding-bottom: 0;
  }

  @media(max-width:600px) {
    .flex--form {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    header.act #logo {
      margin-top: 10px;
    }

    #logo {

      margin-top: 10px;
    }
  }

  @media(max-width:990px) {
    .flex--form {
      margin-top: 15px;
      margin-bottom: 15px;
    }

  }

  @media(min-width:1500px) {
    .flex--form {
      margin-top: 250px;
    }
  }

  .margintopsection {
    margin-top: 125px;
  }

  @media(min-width:1500px) {
    .margintopsection {
      margin-top: 250px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .margintopsection {
      margin-top: 500px;
    }

    .flex--form {
      margin-top: 500px;
    }
  }

  .marginbottomtext {
    margin-bottom: 40px !important;
  }

  .paddingbottomzero {
    padding-bottom: 0 !important;
  }

  @media(max-width:990px) {
    .margintopsection {
      margin-top: 10px;
    }
  }

  .logoposition {
    position: relative;
    top: 20px;
    left: 20px;
    z-index: 1;
  }

  .widthlogometaild {
    width: 300px;
  }

  .chromewidth {
    width: 400px;
  }

  @media(max-width:600px) {
    .chromewidth {
      width: 100%;
    }

    .widthlogometaild {
      width: 280px;
    }
  }

  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 1px solid #144E82 !important;
  }

  /*buttons*/
  .buttonagenda {

    color: #fff;
    font-family: "JosefinSans-600", sans-serif;
    display: inline-block;
    text-align: center;
    /* line-height: 47px; */
    background-color: #144E82;
    border: 1.5px solid #144E82;
    height: 45px;
    width: 230px;
    text-transform: uppercase;
    background-image: linear-gradient(to right, #fff, #fff 50%, #144E82 50%);
    background-size: 210% 100%;
    background-position: 99%;
  }

  .buttonagenda:active,
  .buttonagenda:focus,
  .buttonagenda:hover {
    transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
    background-position: 0%;
    color: #144E82;
  }

  .fs-1 {
    font-size: 0.6375rem;
  }